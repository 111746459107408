import React from "react";

import { toast } from "react-toastify";
import {
  getBalances,
  getTransactions,
  loadMoreTransactions,
  getWalletCashValue,
  checkIsWithdrawalAllowed,
  withdrawAction,
  transferTokens,
} from "../../services/appService";
import Modal, { GreenButton } from "./modal";
import { estimatedCpro } from "../../services/appService";
import { Formik, Form } from "formik";
import { ethers } from "ethers";
import Input from "../fields/Input";
import * as Yup from 'yup';

export default function MyWalletModal(props) {
  const [transferCpro, setTransferCpro] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");
  const [balances, setBalances] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);
  const [cashValue, setCashValue] = React.useState(0);
  const [cproBalance, setCproBalance] = React.useState(0);
  const [pendingCproBalance, setPendingCproBalance] = React.useState(0);
  const [withdrawCpro, setWithdrawCpro] = React.useState(false);
  const [isWithdrawalAllowed, setIsWithdrawalAllowed] = React.useState(false);

  React.useEffect(() => {
    setImageUrl(
      window.innerWidth > 480 ? 'url("/images/coin-shape-purple.png")' : 'url("/images/coin-shape-purple-mobile.png")'
    );
  }, [window.innerWidth]);

  React.useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const transactionData = await getTransactions();

    setTransactions(transactionData.data.transactions);
    const balanceData = await getBalances();
    setBalances(balanceData.data.balances);
    const userCashAmount = await getWalletCashValue();
    setCashValue(userCashAmount.data.cash_value);
    setPendingCproBalance(userCashAmount.data.pending_cpro)

    // let fetchCproBalance = await getBalanceOf(JSON.stringify(props.user.crypto_address));
    // let anotherFetch = await getBalanceOf("0xd2e8b9a4a3d4a02ba64efc9f9fd91f2b91afc0e3");
    // if (!fetchCproBalance.errors) {
    //   setCproBalance(fetchCproBalance.data.balance);
    // } else {
    //   toast.error(fetchCproBalance.errors[0].message);
    // }
    const withdrawalAllowed = await checkIsWithdrawalAllowed();
    setIsWithdrawalAllowed(withdrawalAllowed);
    const cproValue = await estimatedCpro();
    setCproBalance(parseInt(cproValue.data.estimated_shares));
  }

  Yup.addMethod(Yup.string, "cryptoAddressIsValid", function (errorMessage) {
    return this.test(`crypto-address-is-valid`, errorMessage, function (value) {
      const { path, createError } = this;
      return (value && ethers.utils.isAddress(value)) || createError({ path, message: errorMessage });
    });
  });

  const withdrawSchema = Yup.object().shape({
    crypto_address: Yup.string()
      .required("Required")
      .cryptoAddressIsValid("Invalid address"),
    amount: Yup.number()
      .required("Required")
      .min(1, "Minimum amount is 1"),
  });

  const transferSchema = Yup.object().shape({
    email: Yup.string()
      .required("Required"),
    amount: Yup.number()
      .required("Required")
      .min(1, "Minimum amount is 1"),
  });

  async function loadMore() {
    if (transactions.data.length > 9) {
      if(!transactions.next_page_url) {
        toast.info("No more transactions to load");
        return
      }
      const moreTransactions = await loadMoreTransactions(transactions.next_page_url);
      setTransactions((prevState) => {
        let temporaryData = { ...prevState };
        temporaryData.data = [...prevState.data, ...moreTransactions.data];
        temporaryData.next_page_url = moreTransactions.next_page_url;
        if (temporaryData === prevState) {
          toast.info("No more transactions to load");
        }
        return temporaryData;
      });
    } else {
      toast.info("No more transactions to load");
    }
  }

  return (
    <Modal closeModal={() => props.closeModal()} wide={true}>
      <div className="buy-cpro-modal-width">
        <div
          
          className={`bg-no-repeat bg-contain box-shadow flex flex-col z-[1000] min-w-[300px] opacity-100 top-[110px] lg:top-[60px] !p-0`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className=" flex flex-col" style={{
            backgroundImage: imageUrl,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}>
            <h2 className="w-full text-center pt-6 pb-4 px-12 sm:px-28">
              {props.user.first_name ? props.user.first_name : props.user.name}'s CPRO Wallet
            </h2>
            <div className="bg-black flex items-center justify-center relative py-4">
              <div className="absolute h-full flex items-center left-[24px]">
                <img
                  src="/images/CPRO.png"
                  alt="coin icon"
                  className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                  loading="lazy"
                />
              </div>
              <h3 className="font-semibold text-xl">USD Cash Value: ${new Intl.NumberFormat().format(cashValue)}</h3>
            </div>
            <div className="flex flex-col sm:flex-row p-4">
              <div className="w-1/2 flex flex-col ">
                <p>
                  {props.user.first_name} {props.user.last_name}
                </p>
                <p>Join date: {new Date(props.user.created_at).toDateString().split(" ").slice(1).join(" ")}</p>
                <p className="mb-4">Member number {props.user.user_id} </p>
                <p className="font-semibold">NFT Portfolio:</p>
                <div className="flex gap-2 mt-2">

                  {props.user?.addons?.includes('wizard') && <img src="/icons/icon-magichat.svg" alt="nft" className="w-[50px]" />}

                  {/* Removed on Jan's request */}
                  {/* <img src="/icons/icon-magicstar.svg" alt="nft" />
                  <img src="/icons/icon-simplestar.svg" alt="nft" /> */}
                </div>
              </div>

              {balances?.length > 0 && (
                <div className=" w-1/2 flex flex-col sm:pl-6 mt-4 sm:mt-0">
                  {/* CPRO: {cproBalance.toFixed(8)} */}
                  {balances.map(
                    (balance, k) =>
                      balance.currency_code === "CPRO" && (
                        <p key={k}>{balance.currency_code + " : " + Number(balance.display_balance).toFixed(8)}</p>
                      )
                  )}
                  <p>{'Pending CPRO'+ " : " + Number(pendingCproBalance).toFixed(8)}</p>
                </div>
              )}
            </div>
            <div
              className={`flex flex-col sm:flex-row justify-between px-2 ${transferCpro || withdrawCpro ? "max-h-[60px]" : "pb-5"
                } overflow-hidden`}
            >
              <button
                className="w-full sm:w-max min-w-max orange-button px-5 py-2 font-semibold h-[36px] text-[15px]"
                onClick={() => props.openBuyCproModal()}
                disabled={!props.isStoActive}
              >
                Buy more CPROs
              </button>
                <button
                  disabled={!isWithdrawalAllowed}
                  className={`orange-button px-5 py-2 font-semibold w-max text-[15px]  ${withdrawCpro ? "h-[62px] !rounded-b-none pb-7 pt-1" : "h-[36px]"
                    }`}
                  onClick={() => {
                    setTransferCpro(false);
                    setWithdrawCpro(!withdrawCpro);
                  }}
                >
                  Withdraw CPROs
                </button>
                <button
                  className={`orange-button px-5 py-2 font-semibold w-max text-[15px] ${transferCpro ? "h-[62px] !rounded-b-none pb-7 pt-1" : "h-[36px]"
                    }`}
                  onClick={() => {
                    setWithdrawCpro(false);
                    setTransferCpro(!transferCpro);
                  }}
                >
                  Transfer CPROs
                </button>
            </div>
          </div>
          <div className="p-4 bg-white rounded-b-[20px]">
            <h3 className={`text-purple mb-4 font-semibold ${transferCpro || withdrawCpro ? "w-full text-center" : ""}`}>
              {transferCpro
                ? "Send CPROs to another Cryptonite user"
                : withdrawCpro
                  ? "Withdraw CPRO by filling the form below"
                  : "Recent transactions"}
            </h3>
            <div className="w-full h-0 border border-gray"></div>

            {transferCpro ? (
              <div className="flex justify-center">
                {/* <div className="px-3 py-4 border border-gray rounded-[10px] bg-white mt-6 ">
                  {props.user.crypto_address ? <QRCode value={props.user.crypto_address} /> : null}
                </div> */}
                <div className="flex flex-col ml-3 w-2/3">
                  <Formik
                    initialValues={{
                      amount: "",
                      email: ""
                    }}
                    validationSchema={transferSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);
                      const sendTokenSuccessful = await transferTokens(values.email,values.amount);
                      if(sendTokenSuccessful) {
                        setSubmitting(false);
                        resetForm()
                        toast.success(`${values.amount} CPRO transferred successfully to ${values.email}`);
                      }
                    }}
                  >
                    {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                      <Form className="pt-6">
                        <Input name="email" label="Email" />
                        <Input name="amount" label="Amount" />

                        <GreenButton
                          active={true}
                          type="submit"
                          isSubmitting={isSubmitting}
                        // buttonClicked={() => {
                        //   if (props.user.crypto_address) {
                        //     navigator.clipboard.writeText(props.user.crypto_address);
                        //     toast.success("Copied to clipboard");
                        //   }
                        // }}
                        >
                          Transfer tokens
                        </GreenButton>
                      </Form>
                    )}

                  </Formik>



                  <p
                    onClick={() => setTransferCpro(false)}
                    className="underline text-black w-full h-full flex items-end justify-evenly cursor-pointer mt-2"
                  >
                    Cancel
                  </p>
                </div>
              </div>
            ) : withdrawCpro ? (
              <div>
                <div className="flex justify-center">
                  <div className="flex flex-col ml-3 w-2/3 ">
                    <Formik
                      initialValues={{
                        amount: "",
                        crypto_address: "",
                      }}
                      validationSchema={withdrawSchema}
                      onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setSubmitting(true);

                        const response = await withdrawAction(values.amount, values.crypto_address);
                        if(response.status === 200) {
                          toast.success(`${values.amount} CPRO withdrawn successfully`);
                        }
                        resetForm();

                        setSubmitting(false);
                      }}
                    >
                      {({ values, handleChange, handleSubmit }) => (
                        <Form className="pt-6">
                     
                          <Input name="amount" label="Amount in CPRO" />
                          <Input name="crypto_address" label="Withdrawal address" />

                          {/* <label className="text-black mt-2">Amount in CPRO</label>
                          <input
                            className="w-full border border-gray rounded-[10px] bg-white text-black py-4 px-5"
                            placeholder="Enter withdrawAmount"
                            onChange={handleChange}
                          /> */}

                          <GreenButton active={true}>Withdraw CPRO</GreenButton>
                        </Form>
                      )}
                    </Formik>

                    <p
                      onClick={() => setWithdrawCpro(false)}
                      className="underline text-black w-full h-full flex items-end justify-evenly cursor-pointer mt-2"
                    >
                      Cancel
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {transactions?.data?.map((transaction, k) => (
                  transaction.display_type !== 'Deposit' && (
                  <div className="my-2 flex pb-2 border-b border-gray" key={k}>
                    <img
                      alt={transaction.currency_code}
                      src={`/images/${transaction.currency_code}.png`}
                      className="w-[54px] h-[54px]"
                    />
                    <div className="flex w-full ">
                      <div className="flex flex-col ml-3 w-1/2">
                        <p className="text-black mb-3 font-semibold">{transaction.display_type}</p>
                        <p className="text-gray">{new Date(transaction.created_at).toLocaleDateString()} - Paid with</p>
                      </div>
                      <div className="flex flex-col ml-3 w-1/2">
                        <p className="text-black mb-3 font-semibold">
                          {transaction.display_amount} {transaction.currency_code}
                        </p>
                        <p className="text-gray">
                          {transaction.display_amount} {transaction.currency_code}
                        </p>
                      </div>
                    </div>
                  </div>
                  )
                ))}

                {(transactions && transactions?.next_page_url) && (
                <p
                  className="w-full text-center text-black mt-4 underline flex flex-col items-center cursor-pointer"
                  onClick={() => loadMore()}
                >
                  See more transactions{" "}
                  <img src="/icons/icon-see-more-transactions.svg" alt="Arrow down" className="w-5 h-4 mt-4" />
                </p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
