import React from 'react'
import Modal, { GreenButton } from './modal'

export default function InvestorCongratModal(props) {
    return (
        <Modal closeModal={() => props.closeModal()} wide>
            <div className='flex flex-col items-center buy-cpro-modal-width border-4 border-orange p-4 rounded-[32px]'>
                <div className='flex items-center justify-center my-4'>
                    <img src="/icons/icon-magichat.svg" alt="nft" className='w-[80px] h-[80px]' />
                </div>
                <h2 className='text-purple'>
                    Congratulations Wizard {props.name}!
                </h2>
                <p className='text-black text-center mt-1'>
                To view your new CPRO wallet and Wizard coin rewards and NFT, close this window and use your personal pulldown menu at the top of the page.
                </p>

                <div className='bg-purple p-4 rounded-lg flex flex-col gap-y-3 mt-6'>
                    <h3 className='text-center text-2xl !font-light mb-2'>
                        Upgrade to Cryptonite PRO-Plus membership by purchasing a minimum of $1,950 in CPROs @ $.005
                    </h3>
                    <div className='flex'>
                        <span className='h-full mr-2'>★</span><p> 400,000 additional CPROs at $.005—the lowest price offered (public pre-sale begins at $.0075 and ends at $.01 per CPRO) </p>
                    </div>
                    <div className='flex'>
                        <span className='h-full mr-2'>★</span><p> A three-year PRO-Plus membership ($2,100 value). Includes advanced search, more data views, and greater member access </p>
                    </div>
                    <div className='flex'>
                        <span className='h-full mr-2'>★</span><p>A lifetime distinction as a ‘Founding Member’ with NFT badge to add to your Wizard NFT to be displayed on your profile.</p>
                    </div>

                </div>

                <GreenButton active={true} buttonClicked={() => props.openFoundingModal()}>Upgrade to CPRO-Plus!</GreenButton>


            </div>
        </Modal>
    )
}
