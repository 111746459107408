import React, { useState } from "react";
import { XIcon, ChevronLeftIcon } from "@heroicons/react/outline";

export default function Modal(props) {
  return (
    <div className="flex justify-center h-screen w-screen backdrop-black items-center fixed top-0 left-0 z-[99999] overflow-auto">
      <div
        onClick={(e) => e.stopPropagation()}
        className={`steps-to-buy-modal flex flex-col z-[1000] min-w-[300px] opacity-100 absolute top-[110px] lg:top-[60px]  ${
          props.wide ? "!w-auto !p-0 !rounded-[32px]" : ""
        }`}
      >
        <XIcon
          className="h-5 w-5 absolute top-5 right-5 text-black cursor-pointer z-10"
          stroke="black"
          onClick={() => props.closeModal()}
        />

        {props.back && (
          <ChevronLeftIcon
            className="h-5 w-5 absolute top-5 left-5 text-black cursor-pointer"
            stroke="black"
            onClick={() => props.goBack()}
          />
        )}

        {props.children}
      </div>
    </div>
  );
}

export const GreenButton = (props) => {
  return (
    <div className={`border border-radius-14 ${props.active ? "green-border" : "border-darkgray"} mt-7`}>
      <button
        className={`${
          props.active ? "new-green-bg" : "bg-darkgray"
        } ${props.isSubmitting ? 'button--loading text-opacity-0': ''} text-white px-4 py-2 border-radius-14 white-border w-full button`}
        onClick={() => props.type !== "submit" && props?.buttonClicked()}
        disabled={props.isSubmitting}
        type={props.type}
      >
        {props.children}
      </button>
    </div>
  );
};
