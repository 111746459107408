import React, { useEffect }  from "react";
import Modal, { GreenButton } from "./modal";
 import { toast } from "react-toastify";
import { getGoCryptoPayment } from "../../services/appService";

export default function GoCryptoSuccessModal(props) {
const [referenceId, setReferenceId] = React.useState("");
const [payment, setPayment] = React.useState(null);
const [gocPaymentLoaded, setGocPaymentLoaded] = React.useState(false);
async function getPayment() {
        setGocPaymentLoaded(true);
        try {
        const res = await  getGoCryptoPayment(props.gocReferenceId);
        
        if ( res && res.status === 200) {
            setPayment(res.data);
        }
        } catch (err) {
            // console.log(err);
        //   err?.response.data.map((err) => {
        //     toast.error(err);
        //   });
        }
  }

  useEffect(() => {
     
    if(!gocPaymentLoaded)
    getPayment();
  }, [gocPaymentLoaded]);

 
 
  return (
    <Modal closeModal={() => props.closeModal()}>
      <div className="w-full flex justify-center">
        <img alt="success" src="/icons/icon-success.svg"  />
      </div>
       
        
        <h1 className="text-purple text-lg text-center mb-2 mt-4">Go Crypto Payment Completed!</h1>
        <p className=" text-black text-center mt-4">
            Your GoCrypto payment was sucessfuly processed and converted to CPRO.
        </p>

        {payment &&
            <>
            <h3 className="  text-center mt-4">
                <b className="text-dark-gray">
                { payment?.fiat_currency_code }: { payment.display_fiat_amount }$
                </b>    
            </h3>
            <p className=" text-center mt-4 text-gray">
                Converted amount
            </p>
            <h3 className="  text-center mt-4">
            <b className="text-dark-gray">
                { payment.conversion_currency_code }: { payment.display_converted_amount }
                </b>
            </h3>
            
            </> 
        }
       
       
    </Modal>
  );
}
