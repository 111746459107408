import React from "react";
import Input from "../fields/Input";
import Modal, { GreenButton } from "./modal";
import { Formik, Form } from "formik";
import { changePassword, resetPassword } from "../../services/appService";
import { toast } from "react-toastify";

export default function ChangePwModal(props) {
  return (
    <Modal closeModal={() => props.closeModal()}>
      <div onClick={(e) => e.stopPropagation()}>
        <h2 className="text-purple text-lg text-center mb-6">Change Password</h2>
        <Formik
          initialValues={{
            current_password: "",
            new_password: "",
            new_password_confirmation: "",
            email: "",
          }}
          validate={(values) => {
            const errors = {};
            if (props.token) {
              if (!values.email) {
                errors.email = "Required";
              }
            } else {
              if (!values.current_password) {
                errors.currentPassword = "Required";
              }
            }

            if (!values.new_password) {
              errors.newPassword = "Required";
            }

            if (!values.new_password_confirmation) {
              errors.new_password_confirmation = "Required";
            } else if (values.new_password_confirmation !== values.new_password) {
              errors.new_password_confirmation = "Passwords do not match";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            if (props.token) {
              const payload = {
                email: values.email,
                password: values.new_password,
                password_confirmation: values.new_password_confirmation,
                token: props.token,
              };
              
              const response = await resetPassword(payload);
              if(response.data.success) {
                toast.success('Password reset successfully!')
                props.openSignInModal();
              }

            } else {
              const res = await changePassword(values);
              if (res) {
                props.closeModal();
                toast.success("Password changed successfully");
              }
            }

            setSubmitting(false);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
            <Form>
              {!props.token ? (
                <Input name="current_password" label="Current password" type="password" />
              ) : (
                <Input name="email" label="Email" />
              )}
              <Input name="new_password" label="New password" type="password" />
              <Input name="new_password_confirmation" label="Confirm new password" type="password" />
              <GreenButton
                type="submit"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                value="Submit"
                active={Object.keys(errors).length === 0}
                isSubmitting={isSubmitting}
              >
                Confirm new password
              </GreenButton>
            </Form>
          )}
        </Formik>
        <div className="w-full h-0 border border-darkgray mt-7"></div>
        <div className="w-full flex justify-center">
          <p className=" text-black text-center cursor-pointer mt-7" onClick={() => props.openRegisterModal()}>
            Don’t have an account? <span className="cursor-pointer underline text-black">Register here</span>
          </p>
        </div>
      </div>
    </Modal>
  );
}
