import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import config from "./config";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

Sentry.init({
  dsn:
    config.environment === "local" ? null : "https://03cdb1cd1a3340d1b3e4dac6529d8579@o993999.ingest.sentry.io/6420940",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/already-verified" element={<App />} />
        <Route path="/verify-success" element={<App />} />
        <Route path="/go-crypto-success" element={<App />} />
        <Route path="/register/:email/:type" element={<App />} />
        <Route path="/go-crypto-payment-cancelled" element={<App />} />
        <Route path="/reset-password" element={<App />} />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
