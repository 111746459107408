import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";

const baseUrl = config.baseUrl;
let token = localStorage.getItem("access_token") ? localStorage.getItem("access_token") : "";

export async function getAllCountries() {
  const countries = await axios(baseUrl + "/api/countries", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).catch((err) => {
    console.log(err);
  });
  return countries;
}

export function getAllNationalities() {
  const nationalities = axios.get(baseUrl + "/api/nationalities").catch((err) => {
    console.log(err);
  });

  return nationalities;
}

export async function getAllGenders() {
  const genders = await axios.get(baseUrl + "/api/genders").catch((err) => {
    console.log(err);
  });
  return genders;
}

export function registerIndividualUser(values) {
  const payload = {
    email: values.email,
    password: values.password,
    password_confirmation: values.password_confirmation,
    crypto_address: values.crypto_address,
    first_name: values.first_name,
    last_name: values.last_name,
    date_of_birth: values.date_of_birth,
    city_of_birth: values.city_of_birth,
    national_country_id: values.national_country_id,
    residential_address: values.residential_address,
    city: values.city,
    postal_code: values.postal_code,
    country_id: values.country_id,
    gender: values.gender,
    phone_number: values.phone_number,
  };

  return axios.post(baseUrl + "/api/register-individual-user", payload);
}

export function registerCompanyUser(values) {
  const payload = {
    email: values.email,
    password: values.password,
    password_confirmation: values.password_confirmation,
    crypto_address: values.crypto_address,
    name: values.name,
    country_id: values.national_country_id,
    address: values.residential_address,
    postal_code: values.postal_code,
    country_id: values.country_id,
    phone_number: values.phone_number,
    registration_number: values.registration_number,
    registry_code: values.registry_code,
    place_of_registration: values.place_of_registration,
    date_of_registration: values.date_of_registration,
  };

  axios.post(baseUrl + "/api/register-company-user", payload);
}

export async function logIn(user) {
  try {
    let res = await axios.post(baseUrl + "/api/login", user);
    if (res.data.access_token) {
      token = res.data.access_token;
      return res.data;
    }
  } catch (error) {
    return error;
  }
}

export async function getProfile(receivedToken) {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios.get(baseUrl + "/api/profile", {
      headers: {
        Authorization: `Bearer ${receivedToken ? receivedToken : token}`,
      },
    });
    return res;
  } catch (error) {
    return error;
  }
}

export async function getDepositOptions() {
  try {
    const res = await axios.get(baseUrl + "/api/deposit-options", { headers: { Authorization: `Bearer ${token}` } });
    return res.data.deposit_options;
  } catch (err) {
    toast("Unauthenticated", { type: "error" });
    return err;
  }
}

export async function getLastSto() {
  try {
    const res = await axios.get(baseUrl + "/api/last-sto");
    return res.data.sto;
  } catch (err) {
    return err;
  }
}

export async function getStoHistory() {
  try {
    const res = await axios.get(baseUrl + "/api/sto-history", { headers: { Authorization: `Bearer ${token}` } });
    return res.data.sto_history;
  } catch (err) {
    return err;
  }
}

export async function initiateFiatDeposit(payload) {
  try {
    const res = await axios.post(baseUrl + "/api/initiate-fiat-deposit", payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    toast(`Deposit of ${payload.amount + " " + payload.currency_code}  initiated successfully`, {
      type: "info",
    });
    return res;
  } catch (err) {
    toast("There was a problem with deposit!", { type: "error" });
    return err;
  }
}

export async function isUniqueEmail(email) {
  try {
    const res = await axios.post(baseUrl + "/api/validate-is-email-unique", {
      email: email,
    });
    return res.data.is_unique;
  } catch (err) {
    return err;
  }
}

export async function getTransactions() {
  try {
    const res = await axios.get(baseUrl + "/api/transactions", { headers: { Authorization: `Bearer ${token}` } });
    return res;
  } catch (err) {
    return err;
  }
}

export async function getBalances() {
  try {
    const res = await axios.get(baseUrl + "/api/balances", { headers: { Authorization: `Bearer ${token}` } });
    return res;
  } catch (err) {
    return err;
  }
}

export async function loadMoreTransactions(nextPageUrl) {
  try {
    const res = await axios.get(`${nextPageUrl}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res.data.transactions;
  } catch (err) {
    return err;
  }
}

export async function changePassword(payload) {
  try {
    await axios.post(baseUrl + `/api/change-password`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return true;
  } catch (err) {
    err.response.data.map((err) => {
      toast.error(err);
    });
    return false;
  }
}

export async function getWalletCashValue() {
  try {
    const value = await axios.get(baseUrl + `/api/wallet-cash-value`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return value;
  } catch (err) {
    err.response.data.map((err) => {
      toast.error(err);
    });
    return err;
  }
}

export async function resendConfirmationMail(email) {
  try {
    const res = await axios.post(baseUrl + `/api/resend-verification-email`, { email: email });
    return res;
  } catch (err) {
    err.response.data.map((err) => {
      toast.error(err);
    });

    return err;
  }
}

export async function getGoCryptoPayment(referenceId) {
  try {
    const res = await axios.get(baseUrl + `/api/get-gocrypto-payment/` + referenceId, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (err) {
    err.response.data.map((err) => {
      toast.error(err);
    });

    return err;
  }
}

export async function estimatedCpro(email) {
  try {
    const res = await axios.get(baseUrl + `/api/estimated-shares`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (err) {
    err.response.data.map((err) => {
      toast.error(err);
    });

    return err;
  }
}

export async function getUserProfile(email) {
  try {
    const res = await axios.get(baseUrl + `/api/get-user-profile?email=${email}`);
    return res.data.profile;
  } catch (err) {
    err.response.data.map((err) => {
      toast.error(err);
    });

    return err;
  }
}

export function registerInvestor(values) {
  const payload = {
    email: values.email,
    password: values.password,
    password_confirmation: values.password_confirmation,
  };

  return axios.post(baseUrl + "/api/register-investor-user", payload);
}

export async function isRegisterAllowed() {
  const res = await axios.get(baseUrl + "/api/check-if-registration-available");
  return res.data.is_registration_available;
}

export async function verifyRecaptcha(token) {
  const res = await axios.post(baseUrl + "/api/verify-recaptcha-token", { token: token });
  return res.data.is_valid;
}


export async function getGoCryptoRedirectUrl(amount) {
  try {
    const res = await axios.post(baseUrl + `/api/get-gocrypto-payment-url`, { amount: Number(amount) }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return res;
  } catch (err) {
    err.response.data.map((err) => {
      toast.error(err);
    });

    return err;
  }
}

export async function checkIsWithdrawalAllowed() {
  const res = await axios.get(baseUrl + "/api/withdrawals-available");
  return res.data.withdrawals_available;
}

export async function withdrawAction(amount, address) {
  const withdraw = await axios.post(
    baseUrl + "/api/withdraw-tokens",
    { amount: amount, address: address },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return withdraw;
}

export async function forgetPassword(email) {
  try {
    await axios.post(baseUrl + "/api/forgot-password", { email: email });
    return true;
  } catch (err) {
      toast.error('We couldn\'t find an account with that email address.');
    return false;
  }
}

export async function resetPassword(payload) {
  try {
    await axios.post(baseUrl + `/api/reset-password`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return true;
  } catch (err) {
    err.response.data.map((err) => {
      toast.error(err);
    });
    return false;
  }
}

export async function transferTokens(email, amount) {
  try {
    await axios.post(baseUrl + `/api/transfer-tokens`, { email: email, amount: amount }, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return true;
  } catch (err) {
    err.response.data.map((err) => {
      toast.error(err);
    });
    return false;
  }
}