import React from "react";

export default function Footer(props) {
  return (
    <div
      className="
        bg-white lg:bg-black container mx-auto w-full h-full pb-4 pt-14 lg:pt-0 lg:px-0 lg:pb-0 mt-[-1px] lg:mt-8"
    >
      <div className="bg-white container mx-auto w-full h-full rounded-[36px] lg:p-20 lg:mt-8">
        <div className="w-full border-bottom mb-10 h-[60px] lg:h-[50px] ">
          <img
            src="/images/cryptonite logo.svg"
            alt="Cryptonite logo"
            className="h-full pb-4 ml-8 lg:ml-0 mb-8 lg:mb-0"
            loading="lazy"
          />
        </div>
        <div className="w-full flex flex-col lg:flex-row px-9 lg:px-8 lg:p-0">
          <div className="w-full lg:w-3/12 footer-text flex flex-col gap-y-2">
            <p className="font-bold">Cryptonite</p>
            <a
              className="text-[13px]"
              href="https://tonyperkins.medium.com/cryptonites-legend-49c0e4e4574f"
              target="_blank"
            >
              Cryptonite Legend
            </a>
            <a className="text-[13px]" onClick={() => props.scrollToRoadmap()}>
              CPRO Roadmap
            </a>
            <a
              className="text-[13px]"
              href="https://medium.com/the-weekly-rap/what-you-need-to-know-about-your-cryptonite-pro-coins-cpros-de9a553e41a5"
              target="_blank"
            >
              The FAQs
            </a>
            <a className="text-[13px]" href="https://t.me/+LY5UfRSb0rdlNDRh" target="_blank">
              Community
            </a>
          </div>
          <div className="w-full lg:w-4/12 footer-text flex flex-col gap-y-2 mt-4 lg:mt-0 ">
            <p className="font-bold">CPRO Sale Support</p>
            <p className="text-[13px]">
              Questions? <a href="mailto:Faith@CryptoniteVentures.com">Faith@CryptoniteVentures.com</a>
            </p>
            <p className="text-[13px]">
              Sign up for CPRO pre-sale alert list{" "}
              <a href="https://cryptoniteventures.com/ninja-form-cryptonite-pro-coin-pre-sale-alert/" target="_blank">
                here
              </a>
            </p>
            <a
              className="text-[13px]"
              href="https://medium.com/the-weekly-rap/what-you-need-to-know-about-your-cryptonite-pro-coins-cpros-de9a553e41a5"
              target="_blank"
            >
              The CPRO Economy Brief
            </a>
            <p className="text-[13px]">
              Join our Telegram Group{" "}
              <a href="https://t.me/+LY5UfRSb0rdlNDRh" target="_blank">
                here
              </a>
            </p>
          </div>
          <div className="w-full lg:w-5/12 mt-4 lg:mt-0 ">
            <h4 className="font-bold text-black text-[13px]">Our Partners</h4>
            <div className="w-full h-full flex flex-wrap contain-images justify-between">
              <img src="/images/partners.png" alt="Sponzors" loading="lazy" />
            </div>
          </div>
        </div>
        <p className="text-black blue-underline-link w-full border-bottom pb-10 lg:pb-2 mt-8 px-9 lg:px-0 font-medium lg:font-normal mb-10 lg:mb-0 text-base lg:text-[13px] leading-8 lg:leading-none">
          Become a Founding Member{" "}
          <a href="https://tonyperkins.medium.com/cryptonite-foundingmembers-74c4e4b52511" target="_blank">
            here
          </a>
          . <br className="lg:hidden" />+ Apply to be a Wizard{" "}
          <a href="https://tonyperkins.medium.com/cryptonite-wizards-b0a9da29b491" target="_blank">
            here
          </a>
          . <br className="lg:hidden" />+ Sell your stuff in the CPRO Shop{" "}
          <a href="https://cryptoniteventures.com/cproshop-vendor-application/" target="_blank">
            here
          </a>
          .
        </p>
        <div className="flex flex-col-reverse lg:flex-col gap-10 lg:gap-0 px-9 lg:px-0 text-[13px]">
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="flex">
              <p className="text-footergray flex lg:hidden">
                {" "}
                Copyright © 2021 Cryptonite Ventures Corporation | Designed with heart by Cryptonite & Starno in
                California, USA
              </p>
              <div className=" light-gray-text hidden lg:flex ">
                <p className="light-gray-text flex mb-1 h-min text-left text-[13px]">
                  Copyright © 2021 Cryptonite Ventures Corporation |{" "}
                </p>
                <p className="light-gray-text flex  flex-wrap text-[13px] ml-1">
                  Designed with
                  <span className="flex justify-center">
                    <img alt="heart" src="images/heart.svg" className="mx-2 mt-1 w-4 h-min" loading="lazy" />{" "}
                  </span>
                  by Cryptonite & &nbsp;
                  <span className="gray-text"> Starno &nbsp;</span> in California, USA
                </p>
              </div>
            </div>
            <p className="light-gray-text text-[13px] hidden lg:flex">4u Kris10Perk</p>
          </div>
          <div className="flex">
            <div className="flex flex-col lg:flex-row w-1/2 lg:w-max mobile-text-black">
              <span className="gray-text flex mb-2 lg:mb-0 ">
                {" "}
                Privacy Policy <span className="hidden lg:flex gray-text px-2">|</span>
              </span>
              <span className="gray-text flex">
                {" "}
                Terms of Use <span className="hidden lg:flex gray-text px-2">|</span>
              </span>
            </div>
            <div className=" flex flex-col lg:flex-row w-1/2 lg:w-max">
              <span className="gray-text flex mb-2 lg:mb-0">
                {" "}
                Legal <span className="hidden lg:flex gray-text px-2">|</span>
              </span>
              <span className="gray-text"> Site Map</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
