import { ElementsConsumer, CardElement } from "@stripe/react-stripe-js";
import CardSection from "./card-section";
import React, { useMemo } from "react";
import { initiateFiatDeposit } from "../services/appService";
import { toast } from "react-toastify";
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import useResponsiveFontSize from "../helpers/countdown/useResponsiveFontSize";
import { GreenButton } from "./modals/modal";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Open Sans,",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

function CheckoutForm(props) {
  const [amount, setAmount] = React.useState(100);
  const [isSubmiting, setIsSubmiting] = React.useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleSubmit = async (event) => {
    event?.preventDefault();
    setIsSubmiting(true);

    if (amount < 100) {
      toast.error("Minimum amount is $100");
      return
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createToken(elements.getElement(CardNumberElement));
    if (result.error) {
      toast(result.error.message, { error: true });
      setIsSubmiting(false);
    } else {
      const payload = {
        charge_token: result.token.id,
        currency_code: props.currency,
        amount: amount,
      };
      let request = await initiateFiatDeposit(payload);

      setIsSubmiting(false);
      if (request.status === 200) {
        setTimeout(() => {
          props.openMyWalletModal();
        }, 1000)

      }
    }
  };

  return (
    <div className="relative">
      <div className="product-info"></div>
      <form onSubmit={handleSubmit} className="stripe-form">
        <div className="w-full border-b border-gray my-6"></div>
        <label className="text-black">$ Amount</label>
        <input
          name="fiatPayment"
          type="number"
          defaultValue={100}
          step="0.01"
          className="w-full text-black StripeElement"
          onChange={(event) => setAmount(parseFloat(event.target.value, 10))}
        />

        {amount < 100 && (
          <div className="text-red text-sm -mt-2 mb-1">
            Amount must be at least $100
          </div>
        )}
        {props.currency === 'USD' && (
          <>
            <label className="text-black"># of CPROs</label>
            <input
              type="number"
              value={amount / Number(props.cproPrice)}
              step="0.01"
              disabled
              className="w-full text-black StripeElement"
              onChange={(event) => setAmount(parseFloat(event.target.value, 10))}
            />
          </>)}
        <label>
          Card number
          <CardNumberElement options={options} />
        </label>
        <div className="flex w-full">
          <label className="w-3/5">
            Expiration date
            <CardExpiryElement options={options} />
          </label>
          <div className="pl-4 w-2/5">
            <label className="w-full">
              CVC
              <CardCvcElement className="pl-4" options={options} />
            </label>
          </div>
        </div>

        <GreenButton type="submit" active={stripe} isSubmitting={isSubmiting}>
        Purchase CPROs & deposit in your wallet
        </GreenButton>
      </form>
    </div>
  );
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm
          stripe={stripe}
          elements={elements}
          fiatPayment={props.fiatPayment}
          currency={props.currencyToPay}
          cproPrice={props.cproPrice}
          openMyWalletModal={() => props.openMyWalletModal()}
        />
      )}
    </ElementsConsumer>
  );
}
