import React from "react";
import Modal, { GreenButton } from "./modal";
// import QRCode from "react-qr-code";
import { getDepositOptions, getGoCryptoRedirectUrl } from "../../services/appService";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import InjectedCheckoutForm from "../checkout-form";
import config from "../../config";
import { toast } from "react-toastify";

export default function BuyCPROModal(props) {
  const [cryptoCurrency, setCryptoCurrency] = React.useState("USD");
  const [depositOptions, setDepositOptions] = React.useState([]);
  const [crpytoAddress, setCryptoAddress] = React.useState("");
  const [gocAmount, setGocAmount] = React.useState(100);
  const [isSubmiting, setIsSubmiting] = React.useState(false);

  const stripePromise = loadStripe(config.stripeKey);

  async function gocryptoPayment() {
    setIsSubmiting(true);
    const payment = await getGoCryptoRedirectUrl(gocAmount);
    setIsSubmiting(false);
    window.location.href = payment.data.redirect_url;
    window(payment.data.redirect_url);

  }

  React.useEffect(() => {
    (async () => {
      let options = await getDepositOptions();
      options.push({ currency_code: 'Pay with Crypto', is_fiat: false })
      setDepositOptions(options);
      setCryptoAddress(options[0]?.address ? options[0]?.address : "");
    })();
  }, []);


  React.useEffect(() => {
    if (!props.isStoActive) {
      toast.error("Sto is not active");
      props.closeModal()
    }
  }, [])

  return (
    <>
      {props.isStoActive && (

        <Modal closeModal={() => props.closeModal()} wide={true}>

          <div onClick={(e) => e.stopPropagation()} className="buy-cpro-modal-width py-4 px-8 border-4 border-orange !rounded-[32px]">

            <>
              <h2 className="text-purple text-[36px] text-center mt-3">Buy More CPROs <br />@ ${props.price}</h2>
              <p className="text-black text-center mt-2">
                {props.isStoActive ? (
                  <span className="text-black text-base text-center mt-2">
                    Minimum $100 CPRO purchase.
                  </span>
                ) : (
                  <span className="text-black text-center mt-4 h-20">
                    Next stage of token sale has not started yet. Please wait until next stage of CPRO token sale is live.
                  </span>
                )}
              </p>
              <div className="w-full flex gap-2 flex-nowrap overflow-auto pb-2 justify-center mt-8">
                {depositOptions.length > 0 &&
                  depositOptions?.map((option, k) => (
                    <button
                      key={k}
                      className={`border border-gray rounded-[10px] flex items-center text-black w-[180px] px-2 py-2 justify-center ${cryptoCurrency == option.currency_code ? "bg-orange text-white" : "bg-white text-black"
                        }`}
                      onClick={() => {
                        setCryptoCurrency(option.currency_code);
                        if (option.is_fiat === 0) {
                          setCryptoAddress(option.address);
                        }
                      }}
                    >
                      {option.currency_code === 'USD' ? 'Credit/Debit Card' : option.currency_code}
                    </button>
                  ))}
              </div>
            </>


            <>
              {cryptoCurrency === "EUR" || cryptoCurrency === "USD" ? (
                <Elements stripe={stripePromise}>
                  <InjectedCheckoutForm fiatPayment={true} currencyToPay={cryptoCurrency} cproPrice={props.price} openMyWalletModal={() => props.openMyWalletModal()} />
                </Elements>
              ) : (
                <>
                  {depositOptions && (<>
                    <div className="flex flex-col w-full items-center justify-center mt-6 ">
                      <img alt="GoCrypto badge" src="/images/gocrypto-badge.png" className="w-[228px] object-contain h-auto mb-3" />

                      <p className="text-footergray text-sm">
                        Instant and secure crypto payments.
                      </p>

                    </div>

                    <div className="w-full border-b border-gray my-6"></div>

                    <label className="text-black">Amount USD</label>
                    <div className="flex flex-col">
                      <input
                        name="fiatPayment"
                        type="number"
                        defaultValue={gocAmount}
                        step="1"
                        className="w-full text-black StripeElement"
                        onChange={(event) => setGocAmount(parseFloat(event.target.value, 10))}
                      />

                    </div>


                    <GreenButton isSubmitting={isSubmiting} buttonClicked={() => gocryptoPayment()} active>Purchase CPROs & deposit in your wallet</GreenButton>
                  </>


                  )}


                  {/* REMOVED there is no these option on new design of buycpromodal */}
                  {/* <p className="text-sm text-black w-full text-center font-bold mt-7">
                  Scan or copy CPRO to make deposit
                </p>

                <input
                  disabled
                  className="w-full border border-gray rounded-[10px] bg-white text-black py-4 px-5 mt-7"
                  value={crpytoAddress}
                />

                <GreenButton
                  active={true}
                  buttonClicked={() => {
                    navigator.clipboard.writeText(crpytoAddress);
                  }}
                >
                  Copy address
                </GreenButton> */}
                </>
              )}

              <div className="w-full bg-purple flex flex-col p-4 rounded-[10px] mt-8">
                <h3 className="text-center text-2xl font-light">Be a Cryptonite Founder!</h3>
                <p className="mt-4 text-center font-light">
                A minimum purchase of $1,950 CPROs @.005 earned ‘Founding Member’ status, NFT badge, and 3-year PRO-plus membership privileges ($2,100 value).
                </p>
                <p className="text-center mt-4">This offer expires on August 30th, 2022!</p>
              </div>
            </>
          </div>

        </Modal>
      )}

    </>
  );
}

