import React from "react";
import Modal, { GreenButton } from "./modal";
import { resendConfirmationMail } from "../../services/appService";
import { toast } from "react-toastify";

export default function SuccessModal(props) {
  const [email, setEmail] = React.useState("");

  async function resendConfirmation(email) {
    try {
      const res = await resendConfirmationMail(email);
      if (res.status === 200) {
        toast.success("Confirmation mail sent successfully");
        props.closeModal();
      }
    } catch (err) {
      err?.response.data.map((err) => {
        toast.error(err);
      });
    }
  }
  return (
    <Modal closeModal={() => props.closeModal()}>
      <div className="w-full flex justify-center">
        <img alt="success" src="/icons/icon-success.svg" />
      </div>
      {props.emailconfirm ? (
        <>
          <h2 className="text-purple text-[44px] text-center mb-2 ">Success!</h2>
          <p className=" text-black text-center mt-4">
          We have sent you an email to verify your email so you can sign in to your wallet and view your CPRO rewards.
          </p>
          <p className="text-black font-semibold w-full text-center mt-9">Please confirm your email now.</p>
          <input
            className={`border border-gray rounded-[10px] h-[55px] p-5 pt-7 text-black w-full my-2`}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
          />
          <p
            className="text-black underline cursor-pointer w-full text-center"
            onClick={() => resendConfirmation(email)}
          >
            {" "}
            Please resend.
          </p>
        </>
      ) : (
        props.children
      )}

      <GreenButton active={true} buttonClicked={() => props.openSignInModal()}>
        Sign in now!
      </GreenButton>
    </Modal>
  );
}
