import React from "react";
import Input from "../fields/Input";
import Modal, { GreenButton } from "./modal";
import { Formik, Form } from "formik";
import { forgetPassword } from "../../services/appService";
import { toast } from "react-toastify";

export default function ForgotPwModal(props) {
  return (
    <Modal closeModal={() => props.closeModal()}>
      <div onClick={(e) => e.stopPropagation()}>
        <h2 className="text-purple text-lg text-center mb-6">Forgot Password</h2>
        <Formik
          initialValues={{
            email: "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.email) {
              errors.email = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = "Invalid email address";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const response = await forgetPassword(values.email);
            setSubmitting(false);
            if (response?.data?.success) {
              toast.success("Check your email for a password reset link");
              props.closeModal();
            }
            resetForm();
          }}
        >
          {({ errors, isSubmitting }) => (
            <Form>
              <Input name="email" label="Email address" />
              <GreenButton
                type="submit"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                value="Submit"
                active={Object.keys(errors).length === 0}
                isSubmitting={isSubmitting}
              >
                Reset password
              </GreenButton>
            </Form>
          )}
        </Formik>
        <div className="w-full h-0 border border-darkgray mt-7"></div>
        <div className="w-full flex justify-center">
          <p className="underline text-black text-center cursor-pointer mt-7" onClick={() => props.openSignInModal()}>
            Sign into existing account
          </p>
        </div>
      </div>
    </Modal>
  );
}
