import dayjs from "dayjs";
import React from "react";
import useCountDown from "../helpers/countdown/countdown";
import { getLastSto, getStoHistory } from "../services/appService";
import { UserContext } from "../App";
import { useContext } from "react";

export function Counter(props) {
  const timeLeft = useCountDown(props.date);

  return (
    <div className="w-full h-40px flex justify-center">
      <div>
        <span className="countdown-number">{timeLeft.days}</span>
        <p className="text-center">Days</p>
      </div>
      <span className="countdown-dots">{":"}</span>

      <div>
        <span className="countdown-number">{timeLeft.hours}</span>
        <p className="text-center">Hours</p>
      </div>
      <span className="countdown-dots">{":"}</span>
      <div>
        <span className="countdown-number">{timeLeft.minutes}</span>
        <p className="text-center">Minutes</p>
      </div>
      <span className="countdown-dots">{":"}</span>
      <div>
        <span className="countdown-number">{timeLeft.seconds}</span>
        <p className="text-center">Seconds</p>
      </div>
    </div>
  );
}

export default function Privatepresale(props) {
  const [lastSto, setLastSto] = React.useState(null);
  const [stoHistory, setStoHistory] = React.useState(null);
  const [eventStartDate, setEventStartDate] = React.useState(null);
  const [eventEndDate, setEventEndDate] = React.useState(null);
  const user = useContext(UserContext)

  React.useEffect(() => {
    if (user) {
      fetchStoData();
    }
  }, []);

  async function fetchStoData() {
    const last = await getLastSto();
    setLastSto(last);
    if (last) {
      props.stoPricePerCoin(parseFloat(last.display_token_price))
      props.stoIsActive(true);
    }
    const history = await getStoHistory();
    setStoHistory(history);

    setEventStartDate(last?.start_date);
    setEventEndDate(last?.end_date);
    return;
  }

  return (
        <>
          <div className="bg-black lg:rounded-none w-full flex flex-col items-center mt-[-1px]">
            {lastSto && (
              <h3 className="w-full flex justify-center text-center mb-4 lg:mb-0 mt-10 lg:mt-4 text-base lg:text-xl font-light">
                {lastSto?.stage_name} <span className="hidden lg:flex text-base lg:text-xl">—</span>{" "}
                <br className="flex lg:hidden" /> {new Intl.NumberFormat().format(Number(lastSto?.display_shares_amount))} CPROs @ ${parseFloat(lastSto?.display_token_price)} per
                token
              </h3>
            )}
            <h1 className="w-full flex justify-center orange-text text-center mb-4 lg:mb-0 font-light lg:font-extralight text-4xl lg:text-[51px] px-8 leading-[51px]">
              {dayjs().diff(dayjs(lastSto?.start_date)) > 0
                ? "Buy now before the clock runs out!"
                : "Current token sale has ended. Please wait for new one to open."}
            </h1>

            {lastSto && (
              <>
                <h3 className="w-full hidden lg:flex justify-center mt-2 text-base font-semibold">
                  Start Date:{new Date(lastSto?.start_date).toDateString().split(' ').slice(1).join(' ')} — End Date:{" "}
                  {new Date(lastSto?.end_date).toDateString().split(' ').slice(1).join(' ')}
                </h3>
                <h3 className="w-full flex lg:hidden text-center justify-center mt-4 text-base  font-semibold">
                  Start Date:
                  <br className="flex lg:hidden" /> December 15, 2021 12:00:00 PST
                </h3>

                <h3 className="w-full flex lg:hidden justify-center mt-4 text-base text-center font-semibold mb-4">
                  End Date:
                  <br className="flex lg:hidden" /> January 15, 2022 12:00:00 PST
                </h3>
                {eventStartDate && <Counter date={eventEndDate} />}
                <h4 className="mt-4">
                  CPROs still available in current Private Sale: {new Intl.NumberFormat().format(Number(lastSto.display_remaining_shares))}
                </h4>

                <h4 className="flex flex-col w-max lg:flex-row justify-center mt-4 text-base text-center flex-wrap font-light">
                  Accepted Currencies: <br className="flex lg:hidden" />
                  $USD, €Euro, BTC, ETH <br className="flex lg:hidden" />{" "}
                  <span className="hidden lg:flex w-min">&nbsp;— </span>{" "}
                  <span className="mt-4 lg:mt-0">$100 minimum purchase.</span>
                </h4>
              </>
            )}

            <button
              className="orange-button lg:border-0 text-xl mb-4 font-semibold px-8 !py-2 lg:py-1 mt-7 "
              onClick={() => (Object.keys(user.user).length > 0 ? props.openBuyCproModal() : props.openRegisterModal())}
              disabled={!lastSto}
            >
              Purchase more CPRO's now! @ ${parseFloat(lastSto?.display_token_price)}
            </button>
          </div>

          <div className="container mx-auto w-full h-full lg:h-max px-[10%] flex flex-col lg:flex-row gap-10 black-bg lg:mt-0 lg:mb-24 items-end">
            <div className="w-full lg:w-1/5 flex flex-col items-center ">
            <h4 className="orange-bg font-extrabold text-sm uppercase mb-2 h-5 flex items-center px-3 text-center">
                Current
              </h4>
              <p className="text-center font-light">August 30, 2022</p>
              <h4 className="font-bold light-purple-text text-center ">Private OTC Sale</h4>
              <p className="text-center font-semibold leading-[16px]">{new Intl.NumberFormat().format(Number(lastSto?.display_shares_amount))}  CPROs</p>
              <p className="text-center font-semibold leading-[16px]"> @ ${parseFloat(lastSto?.display_token_price)} per coin</p>
            </div>
            <div className="w-full lg:w-1/5 flex flex-col items-center">
            <h4 className="purple-bg font-extrabold text-sm uppercase mb-2 h-5 flex items-center px-3 mt-6 lg:mt-0">
                Upcoming
              </h4>
              <p className="text-center font-light">October 2022</p>
              <h4 className="font-bold light-purple-text text-center">Public Pre-sale #1</h4>
              <p className="text-center font-semibold leading-[16px]">200,000,000 CPROs</p>
              <p className="text-center font-semibold leading-[16px]"> @ $0.0075 per coin</p>
            </div>
            <div className="w-full lg:w-1/5 flex flex-col items-center">
              <div className=" font-semibold text-xl h-8 mb-2 text-center"></div>
              <p className="font-light">November 2022</p>
              <h4 className="font-bold light-purple-text text-center">Public Pre-sale</h4>
              <p className="text-center font-semibold leading-[16px]">300,000,000 CPROs</p>
              <p className="text-center font-semibold leading-[16px]"> @ $0.01 per coin</p>
            </div>
            <div className="w-full lg:w-1/5 flex flex-col items-center">
              <img alt="group" src="/images/Founding member.png" loading="lazy" />
              <h4 className="font-bold light-purple-text text-center">Become a Founding Member</h4>
              <button className="purple-text underline mt-6 lg:mt-0 mb-4 lg:mb-0 !font-base text-center" onClick={() => props.openFoundingModal()}>
              Join Now!
              </button>
            </div>
          </div>
        </>
  );
}
