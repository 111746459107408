import React from "react";

export default function CproRoadmap() {
  return (
    <div
      className="
        bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 md:pt-4 lg:pt-0 lg:px-0 lg:pb-0 mt-[-1px] lg:mt-10"
    >
      <div
        className=" container mx-auto lg:mt-10  p-4 lg:p-0 rounded-[20px] lg:rounded-0 !pb-10"
        style={{
          backgroundImage: "url(/images/roadmap-bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="relative ">
          {/* MOBILE */}
          <div className="flex flex-col lg:hidden">
            <h1 className="w-full text-center top-[50px] mb-6 lg:mb-0 font-[35px] lg:font-size-[40px] font-light lg:font-normal leading-10 lg:leading-[78px]">
              The CPRO Roadmap
            </h1>
            <div className="flex">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">
                  Founded Red Herring magazine in 1993—600,000 global readers
                </p>
                <p className="font-semibold mt-2 text-xs">
                  Anthony Perkins career long dream to connect the global Silicon Valley players onto a intelligence
                  sharing network
                </p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">February 2019</p>
                <p className="font-semibold mt-2 text-xs">
                  14 year old gamer, Reed Perkins, suggests Cryptonite reward tokens for member network performance.
                </p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">February 2020</p>
                <p className="font-semibold mt-2 text-xs">
                  David Kunin joins Tim Draper, Don Basile, and Magomed Musaev as a Founding Cryptonite Advisor
                </p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">June 2021</p>
                <p className="font-semibold mt-2 text-xs">Clickable Beta Version prototype completed with Starno</p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">July 2021</p>
                <p className="font-semibold mt-2 text-xs">21 billion CPRO tokens minted with by Eligma Labs</p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">September 2021</p>
                <p className="font-semibold mt-2 text-xs">Cryptonite Wizards & Scribes Telegram Group</p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">December 2021</p>
                <p className="font-semibold mt-2 text-xs">CPRO token management platform and sale site live!</p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">Present to September 1 2022</p>
                <p className="font-semibold mt-2 text-xs">Private CPRO OTC sale @ $.005 per coin to Wizards and Founding Members</p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">3rd Quarter 2022 </p>
                <p className="font-semibold mt-2 text-xs">Cryptonite 200 Top Company Competition</p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">September 2022</p>
                <p className="font-semibold mt-2 text-xs">Cryptonite Live! Video launch</p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">4th Quarter 2022</p>
                <p className="font-semibold mt-2 text-xs">Public CPRO pre-sale #1 @ $.0075 per coin</p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">4th Quarter 2022</p>
                <p className="font-semibold mt-2 text-xs">Cryptonite Wizard home page launch</p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">4th Quarter 2022</p>
                <p className="font-semibold mt-2 text-xs">Public CPRO pre-sale #2 @ $.01 per coin</p>
              </div>
            </div>
            <div className="flex mt-4">
              <div className="h-full flex justify-start ">
                {/* Orange circle */}
                <div className="w-[13px] h-[13px] rounded-[40px] orange-bg mt-[2px] mr-4"></div>
              </div>
              <div>
                <p className="orange-text font-semibold text-xs">4th Quarter 2022 — First Quarter 2023</p>
                <p className="font-semibold mt-2 text-xs">Public Dutch Auction</p>
              </div>
            </div>
            <div className="w-full flex flex-col mt-6 lg:mt-0">
              <h1 className="text-center font-extralight lg:font-extralight px-4 lg:px-0 text-4xl lg:text-[60px]">
                The Journey is the Reward
              </h1>
              <h4 className="w-full text-base lg:text-[22px] text-center my-4 font-normal lg:font-light px-2">
                Apple CoFounder Steve Jobs rightly observed that in the life of an entrepreneur, ‘The journey is the
                reward.’ Cryptonite achieves its legend by championing global entrepreneurs, great innovation, and the
                wealth-creating spirit for all.
              </h4>
            </div>
          </div>

          {/* DESKTOP */}

          <div className="hidden lg:flex flex-col ">
            <h1 className="w-full text-center mb-6 lg:mb-0 text-[36px] lg:font-size-[40px] font-light lg:font-normal mt-12">
              The CPRO Roadmap
            </h1>

            <div className="w-full h-12 mt-8  flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right "></div>

              <div className="w-1/2"></div>
            </div>

            <div className="w-full h-32  flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>Anthony Perkins</b> career long dream to connect the global Silicon Valley players onto a
                  intelligence sharing network
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">
                    Founded Red Herring magazine in 1993—600,000 global readers
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>14 year old gamer, Reed Perkins,</b> suggests Cryptonite reward tokens for member network
                  performance.
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">February 2019</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>David Kunin joins Tim Draper, Don Basile, and Magomed Musaev as a Founding Cryptonite Advisor</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">February 2020</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>Clickable Beta Version prototype completed with Starno</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">June 2021</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>21 billion CPRO tokens minted with by Eligma Labs</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">July 2021</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>Cryptonite Wizards & Scribes Telegram Group</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex  justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">September 2021</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>CPRO token management platform and sale site live!</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex  justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">December 2021</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>Private CPRO OTC sale @ $.005 per coin to Wizards and Founding Members</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex  justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">Present to September 1 2022</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>Cryptonite 200 Top Company Competition</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex  justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">3rd Quarter 2022</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>Cryptonite Live! Video launch</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex  justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">September 2022</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>Public CPRO pre-sale #1 @ $.0075 per coin</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex  justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">4th Quarter 2022</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>Cryptonite Wizard home page launch !</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex  justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">4th Quarter 2022</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>Public CPRO pre-sale #2 @ $.01 per coin</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex  justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">4th Quarter 2022</p>
                </div>
              </div>
            </div>
            <div className="w-full h-32 flex">
              <div className="w-1/2 border-r border-purple relative h-full flex justify-end  text-right ">
                <p className="font-semibold mt-[22px] text-base w-1/2 mr-32">
                  <b>Public Dutch Auction</b>
                </p>
                <div className="absolute -right-3 top-[22px]">
                  {" "}
                  <img src="/images/timeline-step.svg" alt="Timeline step" />
                </div>
              </div>

              <div className="w-1/2">
                <div className="w-2/3 h-full flex  justify-start text-left ">
                  <p className="font-medium mt-[22px] text-base w-full ml-20 orange-text italic">4th Quarter 2022 — First Quarter 2023</p>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col mt-6 lg:mt-0">
              <h1 className="text-center font-extralight lg:font-extralight px-4 lg:px-0 text-[60px]">
              The Journey is the Reward
              </h1>
              <h4 className="w-full text-[22px] text-center my-4 font-normal lg:font-light px-10">
                Apple CoFounder Steve Jobs rightly observed that in the life of an entrepreneur, ‘The journey is the
                reward.’ Cryptonite achieves its legend by championing global entrepreneurs, great innovation, and the
                wealth-creating spirit for all.
              </h4>
            </div>
          </div>

          {/* <img
                src="/images/roadmap bg.png"
                alt="roadmap background"
                className="w-full h-full hidden lg:flex"
                loading="lazy"
              /> */}

          {/* <img
                src="/images/roadmap.svg"
                alt="roadmap"
                className="absolute roadmap-height-calc top-[120px] !w-full hidden lg:flex"
                loading="lazy"
              /> */}
        </div>
      </div>
    </div>
  );
}
