import React from "react";
import Modal, { GreenButton } from "./modal";
import Input from "../fields/Input";
import { Formik, Form } from "formik";
import { isUniqueEmail } from "../../services/appService";
import * as Yup from "yup";
import { useEffect } from "react";
import debounce from 'lodash/debounce';

export default function RegisterModal(props) {

  const debouncedCheckEmail = debounce(async (email) => {
    return await isUniqueEmail(email);
  }, 500);

  Yup.addMethod(Yup.string, "isEmailUnique", function (errorMessage) {
    return this.test(`email-address-is-unique`, errorMessage, function (value) {
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
      const { path, createError } = this
        return (value && debouncedCheckEmail(value)) || createError({ path, message: errorMessage });
      }
    });
  });

  const schema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Required")
      .isEmailUnique("Email already exists"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });
  return (
    <Modal closeModal={() => props.closeModal()}>
      <div onClick={(e) => e.stopPropagation()}>
        <h2 className="text-purple text-lg text-center mb-6">1: Create a CPRO Account</h2>
        <h4 className="text-black text-center">
          Choose type of account. Company accounts represent investments on behalf of corporations and partnerships.
        </h4>

        <Formik
          initialValues={{
            company: false,
            email: "",
            password: "",
            password_confirmation: "",
          }}

          validationSchema={schema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const uniqueEmail = await isUniqueEmail(values.email);
            setSubmitting(false);
            props.setIdVerificationModal(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            submitCount,
          }) => (
            <Form>
              <div className="flex gap-9 justify-center my-9">
                <div className={`flex flex-col justify-between px-6 py-2 ${!values.company ? 'border-2 border-green rounded-[10px]': 'border-2 border-white' }`}>

                  {values.company ? (
                    <img
                      alt=""
                      src="/images/card-individual-gray.png"
                      onClick={() => setFieldValue("company", false)}
                      className="cursor-pointer object-none"
                    />
                  ) : (
                    <img alt="" src="/images/card-individual-active.png" className="cursor-pointer object-none" />
                  )}
                  <p className="text-black">Individual</p>
                </div>

                <div className={`flex flex-col justify-between  px-6 py-2 ${values.company ? 'border-2 border-green rounded-[10px]': 'border-2 border-white' }`}>
                  {!values.company ? (
                    <img
                      alt=""
                      src="/images/card-company-gray.png"
                      onClick={() => setFieldValue("company", true)}
                      className="cursor-pointer object-none"
                    />
                  ) : (
                    <img alt="" src="/images/card-company-active.png" className="cursor-pointer object-none" />
                  )}
                  <p className="text-black">Company</p>
                </div>
              </div>
              <Input name="email" label="Email address" submitCount={submitCount} />
              {/* {!isUniqueEmailBool && <span className="field-error text-red -mt-2">Email is already taken</span>} */}
              <Input name="password" label="Password" type="password" submitCount={submitCount} />
              <Input name="password_confirmation" label="Confirm password" type="password" submitCount={submitCount} />
              <GreenButton
                disabled={isSubmitting && Object.keys(errors).length === 0}
                active={Object.keys(errors).length === 0}
                type="submit"
                isSubmitting={isSubmitting}

              >
                Next step
              </GreenButton>
            </Form>
          )}
        </Formik>
        <div className="flex items-center my-4">
          <div className="w-full h-0 border border-darkgray"></div> <div className="text-black px-2">or</div>{" "}
          <div className="w-full h-0 border border-darkgray"></div>
        </div>

        <div className="w-full flex justify-center">
          <p className="underline text-black text-center cursor-pointer" onClick={() => props.openSignInModal()}>
            Sign into existing account
          </p>
        </div>
      </div>
    </Modal>
  );
}
