import React, { useEffect } from "react";
import { useField } from "formik";

export const Input = (props) => {
  const { formik = true, layoutClassName = "", label, defaultValue, placeholder, submitCount, ...inputProps } = props;

  const [showPassword, setShowPassword] = React.useState(false);
  const [stateInputProps, setInputProps] = React.useState(inputProps);
  const [showError, setShowError] = React.useState(false);

  const layoutClasses = ["mb-2", layoutClassName ? layoutClassName : undefined];
  const labelClasses = ["label text-black"];
  const [field, meta] = useField(props);

  useEffect(() => {
    if (meta?.error) {
      setShowError(true);
      labelClasses.push("text-red");
    } else {
      setShowError(false);
    }
  }, [meta, field]);

  function revealPassword(passwordBool) {
    if (inputProps["type"] === "password" && passwordBool) {
      inputProps["type"] = "text";
    } else {
      inputProps["type"] = "password";
    }
    setInputProps(inputProps);
  }

  return (
    <div className={layoutClasses.join(" ")}>
      {Boolean(label) && (
        <label className={labelClasses.join("")}>
          {label}
          {Boolean(props.required) && "*"}
        </label>
      )}
      <div className="relative">
        <input
          className={`border border-gray rounded-[10px] h-[55px] p-5 pt-7 text-black w-full ${
            (meta?.error && meta?.touched) || (meta?.error && submitCount > 0) ? "border-red" : ""
          }`}
          placeholder={placeholder ? `${placeholder}${Boolean(props.required) ? "*" : ""}` : undefined}
          {...field}
          {...stateInputProps}
          defaultValue={defaultValue}
          required={false}
          tabIndex={props.tabIndex}
        />
        {props.type == "password" && (
          <img
            alt="password-eye"
            src="/icons/password-eye.svg"
            className="absolute top-[20px] right-[10px]"
            onClick={() => {
              revealPassword(!showPassword);
              setShowPassword(!showPassword);
            }}
          />
        )}
        {((Boolean(meta?.error) && meta?.touched) || submitCount > 0) && (
          <span className="field-error text-red text-xs">{meta.error}</span>
        )}
      </div>
    </div>
  );
};

export default Input;
