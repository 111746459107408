import React from "react";
import "../../App.css";
import Modal from "./modal";

export default function StepsToBuy(props) {
  return (
    <Modal closeModal={() => props.closeModal()}>
      <div onClick={(e) => e.stopPropagation()}>
        <h1 className="purple-text text-center mb-8"> Wizard CPRO Registration</h1>
        <span className="flex items-center">
          <h1 className="green-text mr-4 font-bold">1.</h1>{" "}
          <h4 className="font-medium"> Create a CPRO token account.</h4>
        </span>
        <span className="flex items-center">
          <h1 className="green-text mr-4 font-bold">2.</h1>{" "}
          <h4 className="font-medium"> Complete Cryptonite ID/KYC veriﬁcation. Requires government issued photo ID.</h4>
        </span>
        <span className="flex items-center">
          <h1 className="green-text mr-4 font-bold">3.</h1>{" "}
          <h4 className="font-medium"> Pay $10 KYC processing fee in USD, EUR, BTC, ETH, or USDT</h4>
        </span>
        <span className="flex items-center">
          <h1 className="green-text mr-4 font-bold">4.</h1>{" "}
          <h4 className="font-medium"> Buy and receive your CPRO tokens in your CPRO wallet.</h4>
        </span>
        <div className="border-radius-14 green-border mt-10">
          <button
            className="new-green-bg text-white px-4 py-2 border-radius-14 white-border w-full"
            onClick={() => props.openRegisterModal()}
          >
            Register Now!
          </button>
        </div>
      </div>
    </Modal>
  );
}
