import React from 'react'
import Modal, { GreenButton } from "./modal";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import InjectedCheckoutForm from "../checkout-form";
import config from "../../config";
import { getDepositOptions, getGoCryptoRedirectUrl } from '../../services/appService';



export default function FoundingModal(props) {

    const stripePromise = loadStripe(config.stripeKey);
    const [depositOptions, setDepositOptions] = React.useState([]);
    const [cryptoCurrency, setCryptoCurrency] = React.useState("USD");
    const [gocAmount, setGocAmount] = React.useState(100);


    async function gocryptoPayment() {
        const payment = await getGoCryptoRedirectUrl(gocAmount);
        window.location.href = payment.data.redirect_url;
        window(payment.data.redirect_url);
    
      }


    React.useEffect(() => {
        (async () => {
            let options = await getDepositOptions();
            options.push({ currency_code: 'Pay with Crypto', is_fiat: false })
            setDepositOptions(options);
        })();
    }, []);


    return (
        <Modal closeModal={() => props.closeModal()} wide>
            <div className='flex flex-col items-center buy-cpro-modal-width border-4 border-orange p-4 rounded-[32px]'>
                <div className='bg-purple p-4 rounded-lg flex flex-col gap-y-3 mt-7 px-4 mb-10'>
                    <h3 className='text-center text-[36px] mb-2'>
                        Stay relevant in Web3 <br />Be a Cryptonite Founder!
                    </h3>
                    <div className='flex pl-4 text-[24px]'>
                        <span className='h-full mr-2'>★</span><p className='text-[24px] font-light' >Founding Member status & NFT for life</p>
                    </div>
                    <div className='flex pl-4 text-[24px]'>
                        <span className='h-full mr-2'>★</span><p className='text-[24px] font-light'>3 years of Cryptonite PRO-Plus member privileges (more search, research & access)</p>
                    </div>
                    <div className='flex pl-4 text-[24px]'>
                        <span className='h-full mr-2'>★</span><p className='text-[24px] font-light'>400,000 CPROs @ $.005 per coin</p>
                    </div>
                    <div className='flex pl-4 text-[24px]'>
                        <p className='text-[24px] font-light'>This offer expires on August 30th, 2022!</p>
                    </div>
                    {/* <a href="https://tonyperkins.medium.com/cryptonite-founding-members-74c4e4b52511" className='underline w-full text-center text-sm'>See more FM program details here</a> */}

                </div>

                <div className="w-full flex gap-2 flex-nowrap overflow-auto pb-2 justify-center mt-8">
                    {depositOptions.length > 0 &&
                        depositOptions?.map((option, k) => (
                            <button
                                key={k}
                                className={`border border-gray rounded-[10px] flex items-center text-black w-[180px] px-2 py-2 justify-center ${cryptoCurrency == option.currency_code ? "bg-orange text-white" : "bg-white text-black"
                                    }`}
                                onClick={() => {
                                    setCryptoCurrency(option.currency_code);
                                }}
                            >
                                {option.currency_code === 'USD' ? 'Credit/Debit Card' : option.currency_code}
                            </button>
                        ))}
                </div>

                {cryptoCurrency === "EUR" || cryptoCurrency === "USD" ? (
                    <Elements stripe={stripePromise}>
                        <InjectedCheckoutForm fiatPayment={true} currencyToPay={cryptoCurrency} cproPrice={props.price} openMyWalletModal={() => props.openMyWalletModal()}/>
                    </Elements>
                ) : (
                    <>
                        {depositOptions && (<>
                            <div className="flex flex-col w-full items-center justify-center mt-6 ">
                                <img alt="GoCrypto badge" src="/images/gocrypto-badge.png" className="w-[228px] object-contain h-auto mb-3" />

                                <p className="text-footergray text-sm">
                                    Instant and secure crypto payments.
                                </p>

                            </div>

                            <div className="w-full border-b border-gray my-6"></div>

                            <label className="text-black">Amount USD</label>
                            <div className="flex flex-col">
                                <input
                                    name="fiatPayment"
                                    type="number"
                                    defaultValue={gocAmount}
                                    step="1"
                                    className="w-full text-black StripeElement"
                                    onChange={(event) => setGocAmount(parseFloat(event.target.value, 10))}
                                />

                            </div>


                            <GreenButton buttonClicked={() => gocryptoPayment()} active>Purchase CPROs & deposit in your wallet</GreenButton>
                        </>


                        )}

                    </>
                )}


                <p className='text-purple font-bold pt-6'>Minimum $1,950 CPRO Purchase for Founding Member status</p>


            </div>
        </Modal>
    )
}
