import React from "react";
import Modal, { GreenButton } from "./modal";
import { Formik, Form } from "formik";
import Input from "../fields/Input";
import { registerInvestor, verifyRecaptcha } from "../../services/appService";
import { toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function InvestorModal(props) {

  const { executeRecaptcha } = useGoogleReCaptcha();

  async function handleVerify() {
    const newToken = await executeRecaptcha();
    return await verifyRecaptcha(newToken);
  }

  return (
    <Modal closeModal={() => props.closeModal()} wide>
      <div onClick={(e) => e.stopPropagation()} className="py-4 px-8 border-4 border-orange !rounded-[32px] buy-cpro-modal-width">
        <h2 className="text-purple text-[44px] text-center mb-6">Cryptonite PRO Member Registration</h2>
        <Formik
          initialValues={{
            email: props.email ? props.email : "",
            password: "",
            password_confirmation: "",
            first_name: props.userProfile?.first_name ? props.userProfile?.first_name : "",
            last_name: props.userProfile?.last_name ? props.userProfile?.last_name : "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = "Invalid email address";
            }

            if (!values.password) {
              errors.password = "Required";
            }else if(values.password.length < 8){
            errors.password = "Password must be at least 8 characters long";
            }
            if (!values.password_confirmation) {
              errors.password_confirmation = "Required";
            } else if (values.password_confirmation !== values.password) {
              errors.password_confirmation = "Passwords do not match";
            }

            if (!values.first_name) {
              errors.first_name = "Required";
            }

            if (!values.last_name) {
              errors.last_name = "Required";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const verified = await handleVerify();
            setSubmitting(true);
            if (!verified) {
              return
            }

            const register = await registerInvestor(values);
            setSubmitting(false);
            if (register.status === 200) {
              resetForm();
              toast.success("You've successfullly registered");
              props.closeModal();
              props.openSuccessModal()
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, submitCount }) => (
            <Form className="flex flex-col gap-y-4">
              <div className="grid grid-cols-2 gap-x-4">
              <Input name="first_name" submitCount={submitCount} placeholder="First name" />
              <Input name="last_name" submitCount={submitCount} placeholder="Last name"/>
              </div>

              <Input name="email" submitCount={submitCount} placeholder="Email"/>
              <Input name="password" type="password" submitCount={submitCount} placeholder="Password"/>
              <Input name="password_confirmation" type="password" submitCount={submitCount} placeholder="Confirm Password"/>
              <GreenButton
                type="submit"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                value="Submit"
                active={Object.keys(errors).length === 0}
                isSubmitting={isSubmitting}
              >
                Create membership account
              </GreenButton>

              <div className="w-full flex justify-center">
          <p className="underline text-black text-center cursor-pointer" >
           Already a CPRO member ? <span className="!text-blue" onClick={() => props.openSignInModal()}>Sign in.</span>
          </p>
        </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default InvestorModal;
