const config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  stripeKey:
    process.env.REACT_APP_STRIPE_KEY ||
    "pk_test_51LNC3FDgR0bndneutZYrxgdOoiKTHZDYSQ5FZvWxsus9cfqqJ53sTt8WrQD9I7nT2NLJ26lxze1CfFNNImEnip5a00D3svSoYV",
  pusherKey: process.env.REACT_APP_PUSHER_KEY || "",
  environment: process.env.REACT_APP_ENVIRONMENT,
  networkId: process.env.REACT_APP_NETWORK_ID || 4,
  tokenAddress: process.env.REACT_APP_TOKEN_ADDRESS || "0xDd70BAd8D64Ea2a693E51af36Ca68Da6fE7D7E7E",

  chainId: process.env.MIX_CHAIN_ID || "42",
  chainIdHex: process.env.MIX_CHAIN_HEX || "0x2A",
  chainRpc: process.env.MIX_CHAIN_RPC || "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  sumSubApiUrl: process.env.MIX_SUM_SUB_API_URL || "https://api.sumsub.com",
  explorerTxDetailsUrl: process.env.MIX_EXPLORER_TX_DETAILS_URL || "https://kovan.etherscan.io/tx/",
  siteUrl: process.env.MIX_SITE_URL || "http://localhost:3000/",
  appRelateDate: process.env.MIX_APP_RELEASE_DATE || "2022-01-01 00:00:00",
  indexerApiUrl: process.env.MIX_INDEXER_API_URL || "https://api.thegraph.com/subgraphs/name/omnitaskba/authrail",
  indexerPlatformId: process.env.MIX_INDEXER_PLATFORM_ID || "0x0",
  pusherCluster: process.env.MIX_PUSHER_APP_CLUSTER || "eu",
  pusherAppKey: process.env.MIX_PUSHER_APP_KEY || "83d44d075c6cbcc99119",
  isProduction: process.env.MIX_APP_ENV == "production" ? true : false,
  vueSentryDns: process.env.MIX_SENTRY_VUE_DSN || null,
};

export default config;
