import React, { forwardRef, useEffect } from "react";
import Input from "../fields/Input";
import Modal, { GreenButton } from "./modal";
import { Formik, Form } from "formik";
import {
  getAllCountries,
  getAllGenders,
  getAllNationalities,
  registerCompanyUser,
  registerIndividualUser,
} from "../../services/appService";
import ReactInputDateMask from "../fields/ReactInputDateMask/ReactInputDateMask";
import "react-datepicker/dist/react-datepicker.css";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import * as Yup from "yup";

export default function IdVerificationModal(props) {
  const [user, setUser] = React.useState(props.user);
  const [dropdownVisible, setDropdownVisible] = React.useState("");
  const [countries, setCountries] = React.useState({});
  const [genders, setGenders] = React.useState({});
  const [nationalities, setNationalities] = React.useState({});
  const [isCompany, setIsCompany] = React.useState(false);

  useEffect(() => {
    getAllCountries().then((res) => setCountries(res.data.countries));
    getAllGenders().then((res) => setGenders(res.data.genders));
    getAllNationalities().then((res) => setNationalities(res.data.nationalities));

    if (props?.registrationStep1?.company) {
      setIsCompany(props?.registrationStep1?.company);
    }
    if (user) {
      if (user?.registry_code) {
        setIsCompany(true);
      }
      if (user?.first_name) {
        setIsCompany(false);
      }
    }
  }, []);

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  Yup.addMethod(Yup.date, "is18", function (errorMessage) {
    return this.test(`is-18`, errorMessage, function (value) {
      const { path, createError } = this;
      return (value && Date.parse(value) && getAge(value) >= 18) || createError({ path, message: errorMessage });
    });
  });

  Yup.addMethod(Yup.string, "cryptoAddressIsValid", function (errorMessage) {
    return this.test(`crypto-address-is-valid`, errorMessage, function (value) {
      const { path, createError } = this;
      return (value && ethers.utils.isAddress(value)) || createError({ path, message: errorMessage });
    });
  });

  const IndividualSignupSchema = Yup.object().shape({
    first_name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("First name is required"),
    last_name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Last name is required"),
    date_of_birth: Yup.date().required("Date of birth is equired").is18("You must be 18 years or older to register"),
    city: Yup.string().required("City is required"),
    country_id: Yup.string().required("Country is required"),
    residential_address: Yup.string().required("Residential address is required"),
    postal_code: Yup.string().required("Postal code is required"),
    phone_number: Yup.string().required("Phone number is required"),
    national_country_id: Yup.string().required("Nationality is required"),
    city_of_birth: Yup.string().required("City of birth is required"),
    gender: Yup.string().required("Gender is required"),
    crypto_address: Yup.string()
      .required("Crypto address is required")
      .cryptoAddressIsValid("Invalid wallet address !"),
  });

  const CompanySignUpSChema = Yup.object().shape({
    name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Company name is required"),
    postal_code: Yup.string().required("Postal code is required"),
    country_id: Yup.string().required("Country is required"),
    crypto_address: Yup.string()
      .required("Crypto address is required")
      .cryptoAddressIsValid("Invalid wallet address !"),
    registration_number: Yup.string().required("Registration number is required"),
    registry_code: Yup.string().required("Registry code is required"),
    place_of_registration: Yup.string().required("Place of registration is required"),
    date_of_registration: Yup.date().required("Date of registration is required"),
  });

  return (
    <Modal closeModal={() => props.closeModal()}>
      <div onClick={(e) => e.stopPropagation()}>
        <h2 className="text-purple text-lg text-center mb-6">2: Cryptonite I.D./ KYC Veriﬁcation</h2>
        <p className="w-full text-black text-center mb-6">Takes just a few minutes!</p>
        <Formik
          initialValues={
            user
              ? user
              : {
                first_name: "",
                last_name: "",
                date_of_birth: new Date(),
                city: "",
                city_of_birth: "",
                country_id: "",
                residential_address: "",
                address: "",
                postal_code: "",
                phone_number: "",
                national_country_id: "",
                company_name: "",
                gender: "",
                crypto_address: "",
                company: props.registrationStep1?.company ? props.registrationStep1.company : false,
                email: props.registrationStep1?.email ? props.registrationStep1.email : "",
                password: props.registrationStep1?.password ? props.registrationStep1.password : "",
                password_confirmation: props.registrationStep1?.password_confirmation
                  ? props.registrationStep1?.password_confirmation
                  : "",
                registration_number: "",
                registry_code: "",
                place_of_registration: "",
                date_of_registration: "",
              }
          }
          validationSchema={isCompany ? CompanySignUpSChema : IndividualSignupSchema}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false);
            values.date = new Date(values.date);
            values.email = props.registrationStep1?.email;
            values.password = props.registrationStep1?.password;
            values.password_confirmation = props.registrationStep1?.password_confirmation;
            if (props.registrationStep1?.company) {
              try {
                const res = await registerCompanyUser(values);
                props.openSuccessModal();
              } catch (err) {
                err.response.data.map((error) => toast.error(error));
                toast.error();
              }
            } else {
              try {
                const res = await registerIndividualUser(values);
                props.openSuccessModal();
              } catch (err) {
                err.response.data.map((error) => toast.error(error));
                toast.error();
              }
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            dirty,
            setTouched,
            submitCount,
          }) => (
            <Form>
              {isCompany ? (
                <Input name="name" label="Company name" submitCount={submitCount} tabIndex={1} />
              ) : (
                <>
                  <Input name="first_name" label="Legal first name" submitCount={submitCount} tabIndex={1} />

                  <Input name="last_name" label="Legal last name" submitCount={submitCount} tabIndex={2} />
                  <label className={"text-black"}>Date of Birth</label>
                  {/* <DatePicker
                  className="z-50 h-[55px] mb-1 border border-gray rounded-[10px] p-5 pt-7 text-black w-full "
                  selected={values.date_of_birth}
                  onChange={(date) => {
                    setFieldValue("date_of_birth", new Date(date));
                  }}
                /> */}

                  <ReactInputDateMask
                    mask="mm/dd/yyyy"
                    showMaskOnFocus={true}
                    id="react-date-mask"
                    className={`z-50 h-[55px] mb-1 border border-gray rounded-[10px] p-5 pt-7 text-black w-full  ${(errors.date_of_birth && touched.date_of_birth) || (errors.date_of_birth && submitCount)
                        ? "border-red"
                        : ""
                      }`}
                    value={
                      user?.date_of_birth ? new Date(values.date_of_birth).toLocaleDateString() : values?.date_of_birth
                    }
                    onChange={(date) => {
                      setFieldValue("date_of_birth", date);
                    }}
                    showMaskOnHover={true}
                    tabIndex={2}
                  />
                  {(errors.date_of_birth && touched.date_of_birth) || submitCount ? (
                    <div className="field-error text-red text-xs">{errors.date_of_birth}</div>
                  ) : null}
                  <div className="my-2">
                    <label className={"text-black"}>Nationality</label>
                    <div
                      tabIndex="4"
                      className={`border border-gray text-black ${dropdownVisible === "nationalities" ? "rounded-t-[10px]" : "rounded-[10px]"
                        } min-h-[55px] pt-5 pb-3 px-5 text-darkgray w-full relative mb-1 ${(errors.national_country_id && touched.national_country_id) ||
                          (errors.national_country_id && submitCount)
                          ? "border-red"
                          : ""
                        }`}
                      onFocus={() => setDropdownVisible("nationalities")}
                      onClick={() => setDropdownVisible(dropdownVisible === "nationalities" ? "" : "nationalities")}
                    >
                      <p className="absolute top-[7px] left-[21px] text-xs text-darkgray opacity-50">Nationality*</p>
                      <p className="text-black text-base">
                        {user?.national_country_id
                          ? nationalities[user?.national_country_id]
                          : nationalities[values?.national_country_id]}
                      </p>
                      <img
                        src="/icons/icon-see-more-transactions.svg"
                        alt="Arrow down"
                        className="w-5 h-4 absolute right-[16px] top-[20px] z-10"
                      />

                      {dropdownVisible === "nationalities" && (
                        <div className="absolute top-[53px] left-[-1px] right-[-1px] z-50 bg-white rounded-b-[10px] border border-gray max-h-[200px] overflow-auto">
                          {Object.entries(nationalities).map((national_country_id, k) => (
                            <div
                              key={k}
                              className="text-darkgray border-b border-gray last:border-b-0  min-h-[55px] p-4  w-full cursor-pointer "
                              onClick={() => {
                                setUser((prevState) => ({ ...prevState, national_country_id: national_country_id[0] }));
                                setDropdownVisible(false);
                                setFieldValue("national_country_id", national_country_id[0]);
                              }}
                            >
                              {national_country_id[1]}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    {(errors.national_country_id && touched.national_country_id) || submitCount ? (
                      <div className="field-error text-red text-xs">{errors.national_country_id}</div>
                    ) : null}
                  </div>
                  <Input name="city" label="City or town" submitCount={submitCount} tabIndex={5} />
                  <Input name="city_of_birth" label="Birth city or town" submitCount={submitCount} tabIndex={6} />
                </>
              )}

              {!isCompany ? (
                <Input name="residential_address" label="Residential address" submitCount={submitCount} tabIndex={6} />
              ) : (
                <Input name="address" label="Address" submitCount={submitCount} tabIndex={3} />
              )}

              <Input name="postal_code" label="Postal code" submitCount={submitCount} tabIndex={7} />
              <label className={"text-black"}>Country</label>
              <div
                className={`border border-gray text-black ${dropdownVisible === "countries" ? "rounded-t-[10px]" : "rounded-[10px]"
                  } min-h-[55px] pt-5 pb-3 px-5 text-darkgray w-full relative mb-1 ${(errors.country_id && touched.country_id) || (errors.country_id && submitCount) ? "border-red" : ""
                  }`}
                onClick={() => setDropdownVisible(dropdownVisible === "countries" ? "" : "countries")}
                tabIndex={8}
                onFocus={() => setDropdownVisible("countries")}
              >
                <p className="absolute top-[7px] left-[21px] text-xs text-darkgray opacity-50">Country*</p>
                <p className="text-black text-base">
                  {user?.country_id ? countries[user?.country_id] : countries[values.country_id]}
                </p>
                <img
                  src="/icons/icon-see-more-transactions.svg"
                  alt="Arrow down"
                  className="w-5 h-4 absolute right-[16px] top-[20px] z-10"
                />

                {dropdownVisible === "countries" && (
                  <div className="absolute top-[53px] left-[-1px] right-[-1px] z-50 bg-white rounded-b-[10px] border border-gray max-h-[200px] overflow-auto">
                    {Object.entries(countries).map((country, k) => (
                      <div
                        key={k}
                        className="text-darkgray border-b border-gray last:border-b-0  min-hh-[55px] p-4  w-full cursor-pointer "
                        onClick={() => {
                          setUser((prevState) => ({ ...prevState, country: country[0] }));
                          setDropdownVisible(false);
                          setFieldValue("country_id", Number(country[0]));
                        }}
                      >
                        {country[1]}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {(errors.country_id && touched.country_id) || (errors.country_id && submitCount) ? (
                <div className="field-error text-red text-xs">{errors.country_id}</div>
              ) : null}

              {!isCompany && (
                <div className="mt-2 mb-2">
                  <label className={"text-black"}>Gender</label>
                  <div
                    className={`border border-gray text-black ${dropdownVisible === "genders" ? "rounded-t-[10px]" : "rounded-[10px]"
                      } min-h-[55px] pt-5 pb-3 px-5 text-darkgray w-full relative mb-1 ${(errors.gender && touched.gender) || (errors.gender && submitCount) ? "border-red" : ""
                      }`}
                    onClick={() => setDropdownVisible(dropdownVisible === "genders" ? "" : "genders")}
                    tabIndex={9}
                    onFocus={() => setDropdownVisible("genders")}
                  >
                    <p className="absolute top-[7px] left-[21px] text-xs text-darkgray opacity-50">Gender*</p>
                    <p className="text-black text-base">
                      {genders[user?.gender] ? genders[user?.gender] : user?.gender}
                    </p>
                    <img
                      src="/icons/icon-see-more-transactions.svg"
                      alt="Arrow down"
                      className="w-5 h-4 absolute right-[16px] top-[20px] z-10"
                    />

                    {dropdownVisible === "genders" && (
                      <div className="absolute top-[53px] left-[-1px] right-[-1px] z-50 bg-white rounded-b-[10px] border border-gray max-h-[200px] overflow-auto">
                        {Object.entries(genders).map((gender, k) => (
                          <div
                            key={k}
                            name={gender[1]}
                            className="text-darkgray border-b border-gray last:border-b-0  min-h-[55px] p-4  w-full cursor-pointer "
                            onClick={() => {
                              setUser((prevState) => ({ ...prevState, gender: gender[1] }));
                              setDropdownVisible(false);
                              setFieldValue("gender", Number(gender[0]));
                            }}
                          >
                            {gender[1]}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {(errors.gender && touched.gender) || submitCount ? (
                    <div className="field-error text-red text-xs">{errors.gender}</div>
                  ) : null}
                </div>
              )}

              {!isCompany && <Input name="phone_number" label="Phone number" submitCount={submitCount} tabIndex={10} />}
              <Input name="crypto_address" label="Crypto address" submitCount={submitCount} tabIndex={11} />
              {isCompany && (
                <>
                  <Input name="registration_number" label="Registration number" submitCount={submitCount} tabIndex={4} />
                  <Input name="registry_code" label="Registry code" submitCount={submitCount} tabIndex={5} />
                  <Input name="place_of_registration" label="Place of registration" submitCount={submitCount} tabIndex={6} />
                  <label className={"text-black"}>Date of registration</label>
                  <ReactInputDateMask
                    mask="dd/mm/yyyy"
                    showMaskOnFocus={true}
                    className="z-50 h-[55px] mb-1 border border-gray rounded-[10px] p-5 pt-7 text-black w-full"
                    value={values.date_of_registration}
                    onChange={(date) => {
                      setFieldValue("date_of_registration", date);
                    }}
                    showMaskOnHover={true}
                    tabindex={6}
                  />
                  {(errors.date_of_registration && touched.date_of_registration) || submitCount ? (
                    <div className="field-error text-red text-xs">{errors.date_of_registration}</div>
                  ) : null}

                </>
              )}

              {props?.registrationStep1 ? (
                <GreenButton disabled={isSubmitting} value="Submit" active={true} type="submit">
                  Finish
                </GreenButton>
              ) : (
                <GreenButton active={Object.keys(errors).length === 0} buttonClicked={() => props.closeModal()}>
                  OK
                </GreenButton>
              )}
            </Form>
          )}
        </Formik>
        <div className="flex items-center my-4">
          <div className="text-black px-2 text-center text-sm">
            *If your country does not appear on the list you may not be eligible to purchase CPROs
          </div>{" "}
        </div>
      </div>
    </Modal>
  );
}
