import React from "react";
import Modal, { GreenButton } from "./modal";
import { Formik, Form } from "formik";
import Input from "../fields/Input";
import { logIn } from "../../services/appService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resendConfirmationMail } from "../../services/appService";

export default function SignInModal(props) {
  const [loginError, setLoginError] = React.useState("");
  const [email, setEmail] = React.useState("");

  async function resendConfirmation(email) {
    try {
      const res = await resendConfirmationMail(email);
      if (res.status === 200) {
        toast.success("Confirmation mail sent successfully, please check your email and confirm your email address.");
        setLoginError("");
      }
    } catch (err) {
      err?.response.data.map((err) => {
        toast.error(err);
      });
    }
  }

  return (
    <Modal closeModal={() => props.closeModal()}>
      <div onClick={(e) => e.stopPropagation()}>
        <h2 className="text-purple text-[44px] text-center mb-6">Sign In</h2>
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
              errors.email = "Invalid email address";
            }

            if (!values.password) {
              errors.password = "Required";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            const loginCredentials = { email: values.email, password: values.password };
            const res = await logIn(loginCredentials);
            props.sessionActiveUntil(res.session_expiry)
            setSubmitting(false);
            
            if (res.access_token) {
              localStorage.setItem("access_token", res.access_token);
              await props.signIn(res.access_token);
              if (res.user.is_first_time_login) {
                props.openInvestorCongratModal();
              }
            } else {
              res.response.data.map((error) => {
                toast.error(error, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  className: "text-black",
                });
              });
              setLoginError(res.response.data[0]);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, submitCount }) => (
            <Form>
              {loginError ? <div className="text-red text-center">{loginError}</div> : null}
              {loginError === "Please verify your email" && (
                <div className="mb-6">
                  <input
                    className={`border border-gray rounded-[10px] h-[55px] p-5 pt-7 text-black w-full my-2`}
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email address"
                  />
                  <p
                    className="text-black underline cursor-pointer w-full text-center"
                    onClick={() => resendConfirmation(email)}
                  >
                    {" "}
                    Please resend verification email
                  </p>
                </div>
              )}
              <Input name="email" label="Email" submitCount={submitCount} />
              <Input name="password" label="Password" type="password" submitCount={submitCount} />
              <GreenButton
                type="submit"
                disabled={isSubmitting || Object.keys(errors).length > 0}
                value="Submit"
                active={Object.keys(errors).length === 0}
                isSubmitting={isSubmitting}
              >
                Sign in now!
              </GreenButton>
            </Form>
          )}
        </Formik>
        <p
          className="text-underline text-black w-full text-center mt-4 cursor-pointer"
          onClick={() => props.openForgetPasswordModal()}
        >
          Reset your password here
        </p>
        <div className="w-full h-0 border border-darkgray mt-7"></div>
        <a href="https://tonyperkins.medium.com/cryptonite-wizards-b0a9da29b491" target="_blank" className="w-full text-center text-black mt-4 flex gap-x-1" onClick={(e) => {
          if(props.isRegisterAllowed) {
            e.preventDefault();
            props.openRegisterModal()
          }
        }}>
          {" "}
          Don’t have an account? <p className="text-black underline cursor-pointer">Apply here</p>
        </a>
      </div>
    </Modal>
  );
}
