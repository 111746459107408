import { useEffect, useState, createContext } from "react";
import "./App.css";
import { Element, animateScroll as scroll, scroller } from "react-scroll";
import StepsToBuy from "./components/modals/steps-to-buy.js";
import RegisterModal from "./components/modals/registermodal";
import ChangePwModal from "./components/modals/changePwModal";
import IdVerificationModal from "./components/modals/idVerificationModal";
import ForgotPwModal from "./components/modals/forgotPwModal";
import SuccessModal from "./components/modals/successModal";
import BuyCPROModal from "./components/modals/BuyCPROModal";
import SignInModal from "./components/modals/signInModal";
import FoundingModal from "./components/modals/foundingModal";
import MyWalletModal from "./components/modals/myWalletModal";
import Privatepresale from "./components/private-presale";
import Link from "react-scroll/modules/components/Link";
import Footer from "./components/footer";
import CproRoadmap from "./components/cpro-roadmap";
import { getProfile, getUserProfile, isRegisterAllowed } from "./services/appService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pusher from "pusher-js";
import config from "./config";
import { useLocation, useParams } from "react-router-dom";
import InvestorModal from "./components/modals/investorModal";
import InvestorCongratModal from "./components/modals/investorCongratModal";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import GoCryptoSuccessModal from "./components/modals/gocryptoSuccessModal";

const UserContext = createContext();

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userDropdownActive, setUserDropdownActive] = useState(false);
  const [showBackToTopIcon, setShowBackToTopIcon] = useState(false);
  const [currentModal, setCurrentModal] = useState("");
  const [gocReferenceId, setGocReferenceId] = useState(null)
  const [user, setUser] = useState({});
  const [registrationStep1, setRegistrationStep1] = useState();
  const [isStoActive, setIsStoActive] = useState(false);
  const [stoPricePerCoin, setStoPricePerCoin] = useState(null);
  const [resetPwToken, setResetPwToken] = useState(null);
  const [userProfile, setUserProfile] = useState({});
  const [registerAllowed, setRegisterAllowed] = useState();
  const [sessionActiveUntil, setSessionActiveUntil] = useState(localStorage.getItem("sessionActiveUntil"));

  const location = useLocation();

  const params = useParams();


  async function getProfileInfo(email) {
    const tempProfile = await getUserProfile(email);
    setUserProfile(tempProfile);
    interceptModalChange("investor");
  }

  function closeModal() {
    setUserDropdownActive(false);
    interceptModalChange("");

  }

  function renderModal() {
    switch (currentModal) {
      case "register":
        return (
          <RegisterModal
            nextStep={() => interceptModalChange("idVerification")}
            closeModal={() => closeModal()}
            setIdVerificationModal={(formValues) => {
              setRegistrationStep1(formValues);
              interceptModalChange("idVerification");
            }}
            openSignInModal={() => interceptModalChange("signIn")}
          />
        );
      case "changePw":
        return <ChangePwModal closeModal={() => closeModal()} token={resetPwToken} openRegisterModal={() => interceptModalChange('register')} openSignInModal={() => interceptModalChange('signIn')} />;
      case "forgotPw":
        return <ForgotPwModal closeModal={() => closeModal()} openSignInModal={() => interceptModalChange("signIn")} />;
      case "idVerification":
        return (
          <IdVerificationModal
            registrationStep1={registrationStep1}
            closeModal={() => closeModal()}
            user={user}
            openSuccessModal={() => interceptModalChange("success")}
          />
        );
      case "success":
        if (user) {
          return (
            <SuccessModal
              closeModal={() => closeModal()}
              openSignInModal={() => interceptModalChange("signIn")}
              emailconfirm={true}
            >
              <p className="text-black"></p>
            </SuccessModal>
          );
        }

      case "success-email-confirm":
        if (user) {
          return (
            <SuccessModal closeModal={() => closeModal()} openSignInModal={() => interceptModalChange("signIn")}>
              <p className="text-black my-6 w-full text-center">You successfully confirmed your email address.</p>
            </SuccessModal>
          );
        }

      case "email-already-confirmed":
        if (user) {
          return (
            <SuccessModal closeModal={() => closeModal()} openSignInModal={() => interceptModalChange("signIn")}>
              <p className="text-black my-6 w-full text-center">Your email is already confirmed.</p>
            </SuccessModal>
          );
        }

      case "go-crypto-success":
        return (
          <GoCryptoSuccessModal
            closeModal={() => closeModal()}
            gocReferenceId={gocReferenceId}
            openSignInModal={() => interceptModalChange("go-crypto-succes")}
          >
            <p className="text-black my-6 w-full text-center">GoCrypto Payment completed.</p>
          </GoCryptoSuccessModal>
        );

      case "buyCpro":
        return (
          <BuyCPROModal
            closeModal={() => closeModal()}
            isStoActive={isStoActive}
            price={stoPricePerCoin}
            openMyWalletModal={() => interceptModalChange("wallet")}
          />
        );
      case "signIn":
        return (
          <SignInModal
            closeModal={() => closeModal()}
            openRegisterModal={() => interceptModalChange("register")}
            signIn={async (token) => {
              await loginSetUser(token);
              interceptModalChange("");
            }}
            openInvestorCongratModal={() => setCurrentModal("investorCongrat")}
            openForgetPasswordModal={() => setCurrentModal("forgotPw")}
            isRegisterAllowed={registerAllowed}
            sessionActiveUntil={(value) => setSessionActiveUntil(value)}
          />
        );
      case "stepsToBuy":
        return (
          <div className="mx-4">
            <StepsToBuy openRegisterModal={() => interceptModalChange("register")} closeModal={() => closeModal()} />
          </div>
        );
      case "wallet":
        return (
          <MyWalletModal
            user={user}
            openBuyCproModal={() => interceptModalChange("buyCpro")}
            closeModal={() => closeModal()}
            isStoActive={isStoActive}
          />
        );

      case "investor":
        return (
          <GoogleReCaptchaProvider reCaptchaKey="6Lelsu0gAAAAACCVKQkR4sjTbO8AgxIuqBrTsPls" language="en-US">
            <InvestorModal
              closeModal={() => closeModal()}
              userProfile={userProfile}
              email={params.email}
              openSuccessModal={() => interceptModalChange("success")}
            />
          </GoogleReCaptchaProvider>
        );

      case "investorCongrat":
        return (
          <InvestorCongratModal
            closeModal={() => closeModal()}
            name={user.first_name}
            openFoundingModal={() => interceptModalChange("founding")}
          />
        );

      case "founding":
        return <FoundingModal closeModal={() => closeModal()} price={stoPricePerCoin} openMyWalletModal={() => interceptModalChange('wallet')} />;
      default:
        <></>;
    }
  }

  const userDropdownButtons = [
    { title: "My CPRO Wallet", icon: "icon-my-wallet.svg", modal: "wallet" },
    {
      title: "Buy More CPROs",
      icon: "icon-buy-more-cprd.svg",
      modal: "buyCpro",
    },
    // {
    //   title: "Cryptonite I.D.",
    //   icon: "icon-profile.svg",
    //   modal: "idVerification",
    // },
    {
      title: "Security/Password",
      icon: "icon-sequrity.svg",
      modal: "changePw",
    },
    { title: "Sign-out", icon: "icon-signout.svg", modal: "", action: "sign-out" },
  ];

  const showHideScrollToTop = () => {
    let yOffset = window.pageYOffset;
    yOffset > 249 ? setShowBackToTopIcon(true) : setShowBackToTopIcon(false);
  };

  // Left functionality for possible scrolling to other elements
  function scrollToElement(element) {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  async function loginSetUser(token = "") {
    if (localStorage.getItem("access_token") || token) {
      const data = await getProfile(token);
      setUser({ ...user, ...data?.data?.profile });
    }
  }


  // Modal validation user permission not logged in and registration allowed
  async function interceptModalChange(modalName) {
    let restricted = false
    let loggedIn = false
    if ((!!Object.keys(user).length || !!Object.keys(userProfile).length)) {
      loggedIn = true
    }

    if (modalName === 'buyCpro' || modalName === 'wallet' || modalName === 'founding' || modalName === 'stepsToBuy') {
      restricted = true
    }

    if (!loggedIn && restricted) {
      toast.error("You need to be logged in to access this feature.");
      return
    }

    if (modalName === 'register') {
      const response = await isRegisterAllowed();
      setRegisterAllowed(response);
      if (!response) {
        toast.error("Registration is currently disabled. Please try again later. If you have a username and password, please login in though the sign-in button.");
        return
      }
    }

    setCurrentModal(modalName);
  }

  useEffect(() => {
    if (params.type === "investor") {
      getProfileInfo(params.email);
    }
  }, []);

  useEffect(() => {
    const body = document.getElementsByTagName("body");
    if (currentModal && body) {
      body[0]?.classList?.add("scroll-disabled");
    } else if (body && !currentModal) {
      body[0]?.classList?.remove("scroll-disabled");
    }
  }, [currentModal]);

  useEffect(() => {
    (async () => {
      if (location.pathname === "/already-verified") {
        interceptModalChange("email-already-confirmed");
      } else if (location.pathname === "/verify-success") {
        interceptModalChange("success-email-confirm");
      }

      if (location.pathname === "/go-crypto-success") {
        const queryString = location.search;
        const urlParams = new URLSearchParams(queryString);
        const refrenceId = urlParams.get("payment_id");
        setGocReferenceId(refrenceId);
        interceptModalChange("go-crypto-success");
      }
      if (location.pathname === "/go-crypto-payment-cancelled") {
        const queryString = location.search;
        const urlParams = new URLSearchParams(queryString);
        const status = urlParams.get("status");
        switch (status) {
          case "TERMINATED":
            return toast.info("You cancelled the payment. Please try again.");
          case "DECLINED":
            return toast.info("You payment was declined. Please try again.");
          case "EXPIRED":
            return toast.info("Your payment has expired. Please try again.");
          case "UNDERPAID":
            return toast.info("You payment was underpaid. Please try again.");
          default:
            return toast.info("Your payment was cancelled. Please try again.");
        }
      }
      if (location.pathname === "/reset-password") {
        const queryString = location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get("token");
        // const expireTime = urlParams.get("expires");
        // const now = new Date().getTime();
        // console.log(expireTime, now)
        setResetPwToken(token);
      }
    })();
  }, [location]);

  useEffect(() => {
    if (user) {
      const pusher = new Pusher(config.pusherKey, {
        cluster: "eu",
      });
      const channel = pusher.subscribe(`notifications-${user?.user_id}`);
      channel.bind("fiat-deposit-confirmed", function (data) {
        toast.success(
          `Deposit of ${Number(data.deposit.display_confirmed_amount).toFixed(2)} ${data.deposit.currency_code
          } confirmed`
        );
      });
    }

    window.addEventListener("scroll", showHideScrollToTop);
    return () => window.removeEventListener("keyup", showHideScrollToTop);
  }, [user]);

  useEffect(() => {
    loginSetUser();
  }, []);

  useEffect(() => {
    if (resetPwToken) {
      interceptModalChange("changePw");
    }
  }, [resetPwToken]);

  useEffect(() => {
    let expired = false;
    if (sessionActiveUntil) {
      localStorage.setItem("sessionActiveUntil", sessionActiveUntil);
      setInterval(() => {
        if (new Date(sessionActiveUntil).getTime() - new Date().getTime() < 0) {
          expired = true;
          setUser({});
          localStorage.removeItem("access_token");
          localStorage.removeItem("sessionActiveUntil");
        }
      }, 1000);
    }
    if (expired) {
      toast.info("Your session has expired. Please sign in again.");
    }
  }, [sessionActiveUntil]);

  useEffect(() => {
    (async () => {
      const isAllowed = await isRegisterAllowed()
      setRegisterAllowed(isAllowed)
    }
    )();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: user,
        setUser: setUser,
      }}
    >
      <Element name="top" className="relative" onClick={() => setUserDropdownActive(false)}>
        <ToastContainer></ToastContainer>
        <Element
          name="header"
          className="app-header w-full flex justify-end lg:justify-start items-center fixed h-[50px] lg:h-[50px] lg:relative"
        >
          <div className="container max-w-6xl mx-auto flex items-center relative">
            <div className="h-[50px] w-[50px] mr-10 my-4">
              <img src="/images/heder-logotype.svg" alt="logo" className="ml-4 lg:ml-6 w-full h-full" loading="lazy" />
            </div>
            <div className="hidden lg:flex gap-10  font-normal items-center">
              <Link to="#" className="font-semibold" href="home">
                Home
              </Link>
              <Link to="#" onClick={() => scrollToElement("roadmap")}>
                CPRO Roadmap
              </Link>
              <a
                href="https://medium.com/the-weekly-rap/what-you-need-to-know-about-your-cryptonite-pro-coins-cpros-de9a553e41a5"
                target="_blank"
              >
                CPRO FAQs
              </a>
              <div className="relative  lg:pr-8 min-w-[200px] sm:min-w-[250px]">
                <button
                  className={`purple-button hidden lg:flex ml-2 text-base font-bold justify-center items-center`}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (Object.keys(user).length > 0) {
                      setUserDropdownActive(!userDropdownActive);
                    } else {
                      interceptModalChange("signIn");
                    }
                  }}
                >
                  {Object.keys(user).length === 0
                    ? "Sign-In or Register"
                    : user?.name
                      ? user?.name
                      : `${user?.first_name} ${user?.last_name}`}
                </button>
                {userDropdownActive && (
                  <div className="user-dropdown absolute top-[-8px] sm:top-[-20px] lg:top-[-10px] left-0 z-[1000] min-w-[200px] sm:min-w-[250px]">
                    <div className="purple-bg p-4 h-[50px] sm:h-[75px] lg:h-[52px] flex items-center">
                      {user?.name ? user.name : user?.first_name + ` ` + user?.last_name}
                    </div>
                    <div className="px-2 flex flex-col">
                      {userDropdownButtons.map((button, i) => (
                        <div
                          key={i}
                          className="h-[60px] flex justify-between menu-border-bottom items-center cursor-pointer"
                          onClick={() => {
                            setUserDropdownActive(false);
                            if (button.action === "sign-out") {
                              localStorage.removeItem("access_token");
                              localStorage.removeItem("sessionActiveUntil");
                              setUser({});
                            } else {
                              interceptModalChange(button.modal);
                            }
                          }}
                        >
                          <span className="text-black text-base w-[80%] pl-1">{button.title}</span>
                          <span className="">
                            <img src={`/icons/${button.icon}`} alt="" />
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="lg:hidden relative  lg:pr-8 min-w-[200px] sm:min-w-[250px]">
              <button
                className={`purple-button hidden lg:flex ml-2 text-base font-bold justify-center items-center`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (Object.keys(user).length > 0) {
                    setUserDropdownActive(!userDropdownActive);
                  } else {
                    interceptModalChange("signIn");
                  }
                }}
              >
                {Object.keys(user).length === 0
                  ? "Sign-In or Register"
                  : user?.name
                    ? user?.name
                    : `${user?.first_name} ${user?.last_name}`}
              </button>
              {userDropdownActive && (
                <div className="user-dropdown absolute top-[-8px] sm:top-[-20px] lg:top-[-10px] left-0 z-[1000] min-w-[200px] sm:min-w-[250px]">
                  <div className="purple-bg p-4 h-[50px] sm:h-[75px] lg:h-[52px] flex items-center">
                    {user?.name ? user.name : user?.first_name + ` ` + user?.last_name}
                  </div>
                  <div className="px-2 flex flex-col">
                    {userDropdownButtons.map((button, i) => (
                      <div
                        key={i}
                        className="h-[60px] flex justify-between menu-border-bottom items-center cursor-pointer"
                        onClick={() => {
                          setUserDropdownActive(false);
                          if (button.action === "sign-out") {
                            localStorage.removeItem("access_token");
                            setUser({});
                          } else {
                            interceptModalChange(button.modal);
                          }
                        }}
                      >
                        <span className="text-black text-base w-[80%] pl-1">{button.title}</span>
                        <span className="">
                          <img src={`/icons/${button.icon}`} alt="" />
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="w-[36px] h-[36px] menu-button mr-2 lg:hidden"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          ></div>
        </Element>

        <div
          className={`w-full calc-h-screen container max-w-6xl mx-auto relative bg-black ${isMenuOpen && `scroll-disabled`
            }`}
        >
          <div className={`fixed top-0 left-0 right-0 bottom-0 purple-bg z-[2222] ${isMenuOpen ? `flex` : `hidden`}`}>
            <div className="w-full h-full px-10 py-4">
              <div className="h-[60px] w-full flex justify-between">
                <img src="/images/White logo icon.svg" alt="White logo" loading="lazy" />
                <div className="close-menu-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}></div>
              </div>

              <div className="w-full h-full flex flex-col items-end mt-10">
                <a className="mb-8 text-2xl font-semibold" href="home">
                  Home
                </a>
                <a
                  className="mb-8 text-2xl font-normal"
                  onClick={() => {
                    scrollToElement("roadmap");
                    setIsMenuOpen(false);
                  }}
                >
                  CPRO Roadmap
                </a>
                <a
                  className="mb-16 text-2xl font-normal"
                  href="https://medium.com/the-weekly-rap/what-you-need-to-know-about-your-cryptonite-pro-coins-cpros-de9a553e41a5"
                  target="_blank"
                >
                  CPRO FAQs
                </a>
                <a
                  className="mb-4 text-2xl font-semibold"
                  href="register"
                  onClick={() => {
                    interceptModalChange("register");
                  }}
                >
                  Sign-In or Register
                </a>
              </div>
            </div>
          </div>

          {/* Slice 1 */}

          <div className="homepage-bkr w-full height-correction relative">
            <div className="">
              <img src="/images/header-image-bg.jpg" alt="bkr" className="w-full h-[400px] lg:h-full hidden lg:flex" />

              <div className="flex w-full justify-center absolute bottom-[-16px]">
                <a
                  className="purple-button text-center justify-center mt-4 font-bold lg:hidden py-2 lg:py-0 text-xl "
                  href="https://tonyperkins.medium.com/cryptonite-wizards-b0a9da29b491"
                  target="_blank"
                >
                  {" "}
                  Apply to be a Wizard
                </a>
              </div>
            </div>
            <div className="w-full h-full container mx-auto flex flex-col lg:flex-row justify-between lg:items-center z-[50] absolute top-0 right-0 pl-4 lg:pl-0 lg:left-0">
              <div className="z-[45] w-2/3 lg:w-[28%] h-full mt-8 flex flex-col justify-between pl-4 lg:pl-8 mb-4 sm:mb-0">
                <div className="mt-[40px] lg:mt-0">
                  <h1 className="font-extralight lg:font-normal lg:ml-6 headline sm:mt-4 lg:mt-0">
                    Introducing the Cryptonite PRO Token
                  </h1>
                  <a
                    className="purple-button justify-center mt-4 font-bold !hidden lg:!flex lg:ml-6"
                    href="https://tonyperkins.medium.com/cryptonite-wizards-b0a9da29b491"
                    target="_blank"
                  >
                    {" "}
                    Apply to be a Wizard
                  </a>
                </div>
                <div className="mb-10 lg:mb-20 lg:ml-6">
                  <p className="font-light italic leading-4">Marcus Eagan</p>
                  <p className="font-light italic leading-4">
                    Open Source Geek & <br />
                  </p>
                  <p className="font-light italic leading-4">
                    Crypto Trader <br />
                  </p>
                  <p className="font-light italic leading-4">Cryptonite Wizard</p>
                </div>
              </div>

              <div className="z-[45] w-full lg:w-[30%] pl-4 h-full  hidden lg:flex  flex-col justify-center purple-rectangle mr-8">
                <h1 className="mb-10 text-44"> The Reward Token for Global Innovators</h1>
                <h3 className="text-[24px] leading-[30.15px]">
                  The Cryptonite PRO (CPRO) is the community reward token for global innovators and risk investors who
                  share expert knowledge and opinion on the trends and companies that matter.
                </h3>
                <button
                  className="orange-button mt-10 px-2 py-1 font-bold !text-[13px] "
                  onClick={() =>
                    Object.keys(user).length > 0 ? interceptModalChange("buyCpro") : interceptModalChange("register")
                  }
                  disabled={!isStoActive}
                >
                  Purchase more CPRO's now!
                </button>
              </div>
            </div>
          </div>
          <div className="bg-black px-4">
            <div className="z-[45] w-full lg:w-1/4 pl-4 h-full flex flex-col justify-center purple-rectangle lg:hidden -mt-[1px] sm:mt-[-20px] sm:pt-2 lg:pt-0">
              <h1 className="mb-10 mt-8 font-light lg:font-normal leading-10 lg:leading-[78px]">
                {" "}
                The Reward Token for Global Innovators
              </h1>
              <h4>
                The Cryptonite PRO (CPRO) is the community reward token for global innovators and risk investors who
                share expert knowledge and opinion on the trends and companies that matter.
              </h4>
              <button
                className="orange-button mt-10 px-2 py-1 mb-10 lg:mb-0 font-bold"
                onClick={() =>
                  Object.keys(user).length > 0 ? interceptModalChange("buyCpro") : interceptModalChange("register")
                }
              >
                Purchase more CPRO's now!
              </button>
            </div>
          </div>

          {/* {currentModal === "stripePay" && (
            <Modal closeModal={() => closeModal()} back goBack={() => interceptModalChange("buyCpro")}>
              <Elements stripe={stripePromise}>
                <InjectedCheckoutForm fiatPayment={true} currencyToPay={currencyToPay} />
              </Elements>
            </Modal>
          )} */}

          {showBackToTopIcon && (
            <span className="scroll-to-top" onClick={() => scrollToElement("top")}>
              <img src="/images/icon-back-to-top.svg" alt="" />
            </span>
          )}
          <UserContext.Consumer>
            {(value) => (
              <Privatepresale
                openFoundingModal={() => interceptModalChange("founding")}
                openBuyCproModal={() => interceptModalChange("buyCpro")}
                stoIsActive={(value) => setIsStoActive(value)}
                stoPricePerCoin={(value) => setStoPricePerCoin(value)}
                user={value}
                openRegisterModal={() => interceptModalChange("register")}
              />
            )}
          </UserContext.Consumer>

          {/* Slice 2 */}
          <div className="container mx-auto w-full h-full flex flex-col lg:flex-row md:mt-16 bg-white lg:bg-black lg:overflow-hidden  lg:mb-0">
            <div className="w-full h-full relative lg:hidden lg:w-2/3 lg:overflow-hidden">
              <img
                alt="universe pic"
                src="/images/Group 47.png"
                className="lg:rounded-[44px] w-full lg:w-2/3 lg:mr-10"
                loading="lazy"
              />
              <h4 className="absolute bottom-[-50px] bg-white white-rounded-border box-shadow">
                60 percent of the CPRO mint will fund a metaverse of trusted online communities like Cryptonite where
                members are recognized and rewarded for their participation.
              </h4>
            </div>
            {/* <picture>
              <source
                type="/image/webp"
                srcSet='../public/images/slide-2-img.webp'
                className="rounded-[44px] w-full lg:w-2/3 lg:mr-6"
              ></source>
              <source type="image/jpeg" srcSet="/images/slice-2-image.jpg" className="rounded-[44px] w-full lg:w-2/3 lg:mr-6"/> */}
            <img
              alt="universe pic"
              src="/images/slice-2-image.jpeg"
              className="rounded-[44px] w-[64%] lg:mr-2 hidden lg:flex translate-x-[-1px]"
            />

            <div className="w-full lg:w-[36%] bg-white lg:bg-black px-4 lg:pr-0 mt-16 lg:mt-0 mb-4 lg:mb-0">
              <div className="w-full h-full purple-bg rounded-[36px] block items-center justify-center py-10 mt-2 lg:mt-0">
                <img
                  alt="buble pic"
                  src="/images/Bubble.svg"
                  className="mb-10 sm:mb-16 w-[80px] lg:w-[150px] mx-auto"
                  loading="lazy"
                />
                <h3 className="text-center px-4 lg:mx-0 text-base lg:text-[22px] leading-7 font-normal ">
                  “Successful decentralized social networks will offer a transparency of purpose and economic alignment.
                  The true value of a network’s currency will ultimately be equal to the perceived value of being a
                  member of the community.”
                </h3>
                <h3 className="orange-text text-center mb-10  mt-4 text-base lg:text-[22px] font-normal px-4 lg:mb-4">
                  —Balaji Srinivasan, former CTO of Coinbase & Partner at Andreessen Horowitz
                </h3>
              </div>
            </div>
          </div>

          {/* Slice 3 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full mt-[-1px] lg:mt-0"
          >
            <div className=" h-full flex flex-col lg:flex-row purple-bg rounded-[36px] px-4 lg:px-10 lg:pt-10 pb-4 lg:py-10 lg:mt-8 mx-4 lg:mx-0 relative">
              <div className="w-full lg:w-2/5 lg:px-4">
                <h1 className="mb-6 mt-6 lg:mt-0 font-light lg:font-extralight text-4xl lg:leading-[55px] lg:text-[50px]">
                  The Innovator Marketplace
                </h1>
                <h4 className="font-light">
                  <span className="font-bold">Clever entrepreneurs </span>
                  leveraging innovation to upend industries, transform culture, regenerate our planet, and create new
                  wealth for all.
                </h4>
                <br />
                <h4 className="font-light">
                  <span className="font-bold">Risk investors</span> funding entrepreneurs across all innovation growth
                  sectors. Early developers and adopters of AI, dApps/crypto, VR/AR, robotics, regenerative science,
                  green energy, devices, and vehicles.
                </h4>
                <br />
                <h4 className="font-light">
                  <span className="font-bold">Early developers and adopters</span> of AI, dApps/crypto, VR/AR, robotics,
                  regenerative science, green energy, devices, and vehicles.
                </h4>
                <br />
                <h3 className="font-normal">
                  The CPRO market represents the highest business and consumer purchasing power on the planet.
                </h3>
              </div>
              <div className="w-full lg:w-3/4 flex flex-col-reverse lg:flex-row ">
                <div className="w-full flex flex-col justify-center">
                  <span className="w-full flex justify-center lg:mb-[-22px] z-[5] relative lg:static bottom-[-25px]">
                    <img
                      src="/images/Crypto wizard.svg"
                      alt="crypto wizard"
                      className="w-[80px] lg:w-[160px] "
                      loading="lazy"
                    />
                  </span>
                  <picture>
                    <source
                      srcSet="/images/Piramida.svg"
                      media="(max-width: 1024)"
                      className="justify-self-end"
                      loading="lazy"
                    />
                    <img src="/images/Triangle.svg" alt="triangle" loading="lazy" />
                  </picture>
                </div>
                <div className="w-full lg:w-[100px] flex flex-col static lg:absolute lg:right-[40px] lg:top-[100px]">
                  <div className="w-full h-full flex flex-col items-center lg:items-end mt-8">
                    <picture>
                      <source
                        srcSet="/images/arrow-upward-24px.png"
                        media="(max-width: 1024)"
                        className="text-right"
                        loading="lazy"
                        alt="arrow up"
                        width={30}
                        height={30}
                      />
                      <img src="/images/arrow-up.svg" alt="arrow-up" height={40} width={40} loading="lazy" />
                    </picture>
                    <h3 className="text-right text-xs lg:text-[22px] leading-[29px] font-bold lg:font-extralight ">
                      Global innovation influence and ambition
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Slice 4 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full flex pb-4 lg:pb-0 mt-[-1px] lg:mt-8"
          >
            <div className="flex flex-col orange-bg rounded-[36px] overflow-hidden relative mx-4 lg:mx-0 mt-4 lg:mt-0">
              <div className="w-full flex flex-col pt-10">
                <h1 className="light-purple-text w-full lg:text-center font-massive px-4 lg:px-0 font-extralight leading-10 lg:leading-none">
                  The challenge: <br className="lg:hidden" />
                  How to stay relevant.
                </h1>
                <div className="w-full black-text-block flex flex-col md:flex-row mt-10 px-4 lg:px-10">
                  <p className="w-full md:w-1/4 !text-black text-base pb-4 md:pb-0 md:pr-4 font-light">
                    <span className="font-bold">Infobesity and the cancel culture</span>
                    &nbsp;are stifling innovation and paralyzing decision-making.
                  </p>
                  <p className="w-full md:w-1/4 pb-4 md:pb-0 md:pr-4 text-base font-light">
                    <span className="font-bold">Working virtually </span>
                    —a behavior accelerated by the quarantine—is reducing serendipitous, in-person, creative encounters.
                  </p>
                  <p className="w-full md:w-1/4 pb-4 md:pb-0 md:pr-4 text-base font-light">
                    <span className="font-bold"> First-generation </span>
                    social networks devolved into chaotic, aimless, manipulative, and mistrusted platforms.
                  </p>
                  <p className="w-full md:w-1/4 text-base font-light">
                    <span className="font-bold">You can find meaningful work </span>
                    in online communities that offer transparency of purpose and economic alignment.
                  </p>
                </div>
              </div>
              <div className="relative mt-10 w-full orange-brands-bg">
                <img
                  className="w-full rounded-[26px] hidden lg:flex"
                  alt="brands"
                  src="/images/orange-brands.jpg"
                  loading="lazy"
                />
                <div className="w-full h-auto flex justify-center">
                  <h3 className="dark-purple-text w-[300px] h-[300px] rounded-[200px] bg-white flex items-center justify-center mb-10 lg:mb-0 text-center relative text-3xl md:text-3xl lg:absolute lg:top-[-2rem] font-bold lg:font-extrabold">
                    POST-COVID OVERWHELMED, ISOLATED, AND DISCONNECTED
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div
            className="
          bg-white lg:bg-black container mx-auto w-full  h-max lg:h-full pb-4 lg:pb-0 -mt-[1px] lg:mt-8"
          >
            <div className="black-bg lg:mt-16 rounded-[36px] mx-4 lg:m-0">
              <div className="w-full flex justify-center ">
                <img
                  alt="buble pic"
                  src="/images/Bubble.svg"
                  className="mb-10 mt-10 lg:mt-0 w-[70px] lg:w-[120px]"
                  loading="lazy"
                />
              </div>
              <h2 className="text-center text-xl lg:text-3xl lg:leading-[44px] p-4 lg:p-10 w-full !font-normal">
                “If we have learned anything in the social networking era, it is that our actions not only affect our
                own fate, but the fate of everyone we are connected to. We are not merely insignificant little specs in
                a world of 7 billion people. We are all part of a network and how we operate matters. The average person
                living in the developed world will get to know at least a thousand people in their lifetime. And each of
                those thousand people are connected to another thousand, which means we are all one person away from 1
                million people and 2 persons away from a billion. Each of the ideas we share and actions we take are
                like dropping a stone in a pond, they ripple out into our network in ways we can’t even comprehend. If
                entrepreneurs stay awake and execute on their network affects, you can build game-changing enterprises,
                and enjoy productive and meaningful careers.” <br />
                <span className="orange-text mt-4">—Dr. Jordan Peterson</span>
              </h2>
            </div>
          </div>

          {/* Slice 5*/}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 lg:px-0 lg:pb-0 mt-[-1px] lg:mt-8"
          >
            <div className="light-grayish-bg lg:!bg-white rounded-[36px] p-4 lg:pb-6 lg:pt-0 lg:px-16">
              <div className="w-full flex flex-col z-[10] text-center">
                <span
                  className="flex lg:hidden w-full justify-center my-6
          "
                >
                  {" "}
                  <img src="/images/keys.svg" alt="keys" className="h-[80px]" loading="lazy" />
                </span>
                <h1 className="light-purple-text font-massive z-[10] font-extralight leading-10 lg:leading-[78px]">
                  Join the Trusted Network Era
                </h1>
              </div>
              <div className="w-full flex flex-col md:flex-wrap lg:flex-nowrap md:flex-row lg:gap-20 mt-[-6rem] md:mt-[-4rem]">
                <div className="mt-10 w-full md:w-1/2 lg:w-1/4 flex flex-col items-center justify-start">
                  <img
                    src="/images/green-wallet.svg"
                    alt="green wallet"
                    className="w-[100px] lg:w-full mt-[-2rem] lg:mt-[-5rem]"
                    loading="lazy"
                  />
                  <h3 className="light-purple-text font-bold mt-6 lg:mt-10 text-base lg:text-xl">
                    Private Key Authentication
                  </h3>
                  <p className="text-black text-center lg:text-left pr-4 mt-6 z-[100] text-base font-light">
                    A ‘Cryptonite I.D.’ is a public/private key authentication system that allows validation without
                    sharing their sign-in credentials.
                  </p>
                </div>
                <div className="mt-10 w-full md:w-1/2 lg:w-1/4 flex flex-col justify-start items-center">
                  <div className="relative">
                    <img
                      src="/images/wallet 2.svg"
                      alt="blue wallet"
                      className="w-[100px] lg:w-full mt-[-2rem] lg:mt-[-5rem]"
                      loading="lazy"
                    />
                    <span
                      className="absolute h-[60px] right-[-70px] hidden lg:flex
          "
                    >
                      {" "}
                      <img src="/images/keys.svg" alt="keys" className=" h-[60px]" loading="lazy" />
                    </span>
                  </div>
                  <h3 className="light-purple-text font-bold mt-6 lg:mt-10 lg:h-[60px] text-base lg:text-xl">
                    Data Privacy & Control
                  </h3>
                  <p className="text-black text-center lg:text-left mt-6 z-[100] text-base font-light">
                    Private keys ensure members own and control their I.D., network and transaction data, online
                    privileges, and authorizations.
                  </p>
                </div>
                <div className="mt-10 w-full md:w-1/2 lg:w-1/4 flex flex-col justify-start items-center">
                  <img
                    src="/images/wallet 3.svg"
                    alt="yellow wallet"
                    className="w-[100px] lg:w-full mt-[-2rem] lg:mt-[-5rem]"
                    loading="lazy"
                  />

                  <h3 className="light-purple-text font-bold mt-6 lg:mt-10 text-base lg:text-xl">
                    Decentralized, P2P Network
                  </h3>
                  <p className="text-black text-center lg:text-left mt-6 z-[100] text-base font-light">
                    Users collaborate on a fully encrypted and transparent peer-to-peer basis. Data security and up-time
                    achieved via distributed storage.
                  </p>
                </div>
                <div className="mt-10 w-full md:w-1/2 lg:w-1/4 flex flex-col justify-start items-center">
                  <img
                    src="/images/wallet 4.svg"
                    alt="rose wallet"
                    className="w-[100px] lg:w-full mt-[-2rem] lg:mt-[-5rem]"
                    loading="lazy"
                  />

                  <h3 className="light-purple-text font-bold mt-6 lg:mt-10 text-base lg:text-xl">
                    Self & Community Policing
                  </h3>
                  <p className="text-black text-center lg:text-left mt-6 z-[100] text-base font-light">
                    Users curate content and contacts through a peer-to-peer vouching, rating, sacking and community
                    flagging culture.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Slice 6 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 lg:px-0 lg:pb-0 lg:mt-8 mt-[-1px]"
          >
            <div className="purple-bg w-full lg:mt-8 rounded-[36px] px-4 lg:!px-16 py-4 lg:pb-10 lg:pt-0">
              <h1 className=" w-full text-center font-light lg:font-extralight font-massive">Who do you trust?</h1>
              <div className="relative h-full">
                <div className="h-full">
                  <p className="w-full flex justify-center">
                    <span className="md:w-[250px] orange-text text-[22px] text-center mb-4 mt-2 font-light">
                      Offers member data privacy and control
                    </span>
                  </p>
                  <div className="flex lg:h-full items-center">
                    <div className="lg:h-full flex justify-center text-[22px] orange-text md:w-[260px] pr-1 lg:pr-4 text-center items-center writing-mode-vertical rotate-180 md:rotate-0 font-light">
                      Sells customers’ data for a living
                    </div>
                    <span className="w-full">
                      <img
                        src="/images/arrows.svg"
                        alt="cross-arrows"
                        className="w-full h-full hidden lg:flex"
                        loading="lazy"
                      />
                      <img
                        src="/images/strelice.svg"
                        alt="cross-arrows"
                        className="w-full lg:h-full flex lg:hidden "
                        loading="lazy"
                      />

                      <img
                        src="/images/crypto key.svg"
                        alt="Crypto key"
                        className="w-[100px] lg:w-[150px] h-auto absolute right-[130px] top-0 hidden lg:flex"
                        loading="lazy"
                      />
                    </span>
                    <div className="lg:h-full flex justify-center text-[22px] items-center orange-text md:w-[310px] pl-1 lg:pl-4 text-center writing-mode-vertical rotate-180 md:rotate-0 font-light">
                      Shares revenue with customers via token rewards
                    </div>
                  </div>
                  <p className="w-full flex justify-center">
                    <span className="w-2/3 md:w-[280px] orange-text text-[22px] text-center mt-4 mb-4 lg:mb-0 lines-2 font-light">
                      Centralizes access, and controls all customer data.
                    </span>
                  </p>
                </div>
                <div className="w-[100px] lg:w-[250px] absolute bottom-0 lg:left-[60px]">
                  <img
                    src="/images/cloud of mistrust.svg"
                    alt="Cloud of mistrust"
                    className="w-full h-auto hidden lg:flex"
                    loading="lazy"
                  />
                  <span className="font-bold px-12 text-center hidden lg:flex">Cloud of mistrust</span>
                </div>
              </div>
            </div>
          </div>

          {/* Slice 7  */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 lg:px-0 lg:pb-0 lg:mt-8"
          >
            <div className="gray-bg rounded-[36px] px-4 lg:!px-16 pt-4 lg:py-10 flex flex-col lg:flex-row mt-[-1px] justify-between lg:mt-0 md:overflow-hidden lg:overflow-auto">
              <div className="w-full lg:w-[45%] font-change">
                <h1 className="mb-10 text-4xl lg:text-[51px] leading-[60px] font-light lg:font-normal">
                  Cryptonite - The first CPRO network
                </h1>
                <p className="mb-10 text-base  italic font-normal">
                  <span className="font-bold">A modern knowledge sharing and expert network</span>
                  —A Reddit for the innovation set.
                </p>
                <p className="mb-10 text-base font-normal">
                  <span className="font-bold">Led by industry ‘Wizards’</span>
                  &nbsp;who initiate discussion and debate by sharing content and expert opinion on top trends and
                  companies.
                </p>
                <p className="mb-10 text-base font-normal">
                  <span className="font-bold">Accelerates peer-to-peer collaboration and community action</span>
                  &nbsp; —Meet smart people, forge new partnerships, and make great things happen.
                </p>
                <p className="mb-10 text-base font-normal">
                  <span className="font-bold">A pioneer in live voice and video broadcasting</span>
                  &nbsp; <span className="italic">-Where Clubhouse meets Zoom.</span>
                </p>
                <p className="mb-10 text-base font-normal">
                  <span className="font-bold">Awards CPRO tokens and NFT badges</span>
                  &nbsp; for community contributions and achievements.
                </p>
              </div>
              <div className="w-full lg:w-1/2 object-cover relative">
                <img
                  src="/images/First-cpro-network.png"
                  alt="web-insert"
                  className=" w-full h-full lg:ml-[-1.5rem]"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          {/* Slice 8 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 lg:px-0 lg:pb-0 mt-[-1px] lg:mt-8"
          >
            <div className="light-grayish-bg lg:!bg-white container w-full mx-auto rounded-[36px] px-4 lg:px-10 pt-4 lg:pt-0 lg:mt-6 overflow-hidden">
              <h1 className="light-purple-text w-full text-center lg:font-normal lg:mb-2 font-massive !font-extralight leading-10 lg:leading-[78px]">
                Leader in live voice and video
              </h1>
              <div className="flex flex-col-reverse lg:flex-row justify-between">
                <div className="w-full lg:w-[57%] rounded-[26px] mt-10 relative hidden lg:flex lg:mb-[-1rem]">
                  <img
                    src="/images/investing on mars image.png"
                    alt="investing on mars"
                    className="w-full h-full rounded-[26px]"
                    loading="lazy"
                  />
                  <div className="w-[110%] h-[110%] absolute bottom-[16px] right-[-517px]">
                    <img src="/images/cn mixr live image.png" alt="" className="h-full w-full " loading="lazy" />
                  </div>
                </div>
                <div className="h-[480px] sm:h-[1024px] lg:h-[480px] ">
                  <img
                    alt="Elon Musk"
                    src="/images/elon-musk-mixr.png"
                    className="w-full h-auto flex lg:hidden"
                    loading="lazy"
                  />
                </div>
                <div className="w-full lg:w-1/3  mt-4 lg:mt-14 mb-4 lg:mb-0 text-base lg:flex lg:justify-end text-normal lg:text-light">
                  <span className="text-base font-normal text-black">
                    Cryptonite features live Wizard-hosted video social-casts with rock star entrepreneurs and
                    investors, and voice-based ‘MiXers’ to debate top news, companies, investment and trends.
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Slice 9 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 pt-4 lg:pt-0 lg:px-0 lg:pb-0 mt-[-2px] lg:mt-8 lg:overflow-hidden"
          >
            <div className="purple-bg lg:mt-0 rounded-[36px] pl-4 lg:pl-8 py-4 lg:pb-0 h-full lg:h-[600px] lg:overflow-hidden">
              <h1 className="font-extralight mb-4 lg:mb-0 font-massive pr-4 leading-10 lg:leading-[78px]">
                Uncovering the next great companies
              </h1>
              <div className="w-full flex flex-col lg:flex-row bottom-[-24px] relative">
                <h3 className="lg:w-[475px] text-left lg:text-right lg:absolute lg:top-0 lg:right-[40px] mb-4 mr-4 lg:mr-0 lg:mb-0 lg:!text-[22px] xl:text-xl font-normal">
                  Cryptonite’s research team taps into its global network of Wizards and risk investors to identify and
                  track the companies and altcoins with the greatest upside potential.
                </h3>
                <img
                  src="/images/liste-3-kartice.png"
                  alt="Top 200 index"
                  className="hidden lg:flex ml-[-1.5rem] mb-[-1rem]"
                  loading="lazy"
                />
                <img
                  src="/images/chart-images.png"
                  alt="Top 200 index"
                  className="flex w-full sm:hidden  mb-[-0.5rem] ml-[-0.6rem] relative lg:top-[10px]"
                  loading="lazy"
                />
                <img
                  src="/images/chart-images-ipad.png"
                  alt="Top 200 index"
                  className="w-full hidden sm:flex lg:hidden mb-[-0.5rem] ml-[10px]"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          {/* Slice 10 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4  lg:pt-0 lg:px-0 lg:pb-0 mt-[-1px] lg:mt-8"
          >
            <div className="light-gray-bg rounded-[36px] px-4 lg:px-10 pb-4">
              <h1 className="light-purple-text w-full text-left lg:text-center pt-4 lg:pt-0 mb-6 font-massive font-light lg:font-extralight leading-10 lg:leading-[78px]">
                Cryptonite Live World Tour
              </h1>
              <h3 className="light-purple-text mb-4 text-left lg:text-center mobile-text-black text-base lg:text-[22px] ">
                Cryptonite invites its members on a live world tour led by our Wizards and featuring the best and
                brightest entrepreneurs from each region and the risk investors fueling their enterprises.{" "}
              </h3>
              <picture className="w-full justify-center md:mt-10">
                <source
                  srcSet="/images/gradovi.png"
                  media="(max-width: 480px)"
                  alt="Live world tour"
                  className="w-full"
                  loading="lazy"
                />
                <source
                  srcSet="/images/gradovi-ipad.png"
                  media="(min-width: 481px) and (max-width: 1024px)"
                  alt="Live world tour"
                  className="w-full"
                  loading="lazy"
                />
                <img src="/images/live-world-tour.png" alt="Live world tour" className="w-full" loading="lazy" />
              </picture>
            </div>

            {/* Slice 11 */}
            <div className="light-gray-bg container w-full  mx-auto rounded-[36px] px-4 lg:px-10 py-4 lg:pt-10 lg:pb-0 flex mt-4 lg:mt-8 flex-col lg:flex-row overflow-hidden">
              <div className="flex flex-col lg:w-1/3">
                <h1 className="light-purple-text h-max lg:absolute w-full lg:w-[660px] font-extralight xl:text-6xl leading-10 lg:leading-[78px]">
                  Private Universal Cryptonite Member I.D.
                </h1>
                <h3 className="text-black lg:mt-[170px] w-full lg:w-full my-6 lg:my-0 text-base lg:pr-[65px]">
                  Cryptonite <b className="text-black">features public/private key authentication system</b> stored in a
                  user’s data wallet that allows users to sign-in without sharing credentials.
                </h3>
              </div>
              <picture className="w-full flex justify-center">
                <source
                  srcSet="/images/crpro-wallet-image-mobile.png"
                  media="(max-width: 480px)"
                  className="justify-self-end"
                  loading="lazy"
                  alt="Tony Perkins"
                />
                <img
                  src="/images/cryptonight-member.png"
                  alt="Tony Perkins"
                  className="justify-self-end -mb-[50px]"
                  loading="lazy"
                />
              </picture>
            </div>

            <div className="purple-bg lg:bg-black container mx-auto w-full rounded-[36px] lg:rounded-[0px] flex flex-col justify-center items-center mt-4 lg:mt-0 px-4 mb-10">
              <img
                alt="buble pic"
                src="/images/Bubble.svg"
                className="mt-10 lg:mt-20 mb-10 w-[80px] lg:w-[120px]"
                loading="lazy"
              />
              <div className="text-center w-full lg:w-[90%] mb-10 lg:mb-0">
                <h3 className="text-base lg:text-2xl xl:text-4xl mb-6 font-normal lg:font-light">
                  “Who you know, what you know, and when you know it is the currency of Silicon Valley.”
                </h3>
                <span className="orange-text text-base lg:text-2xl xl:text-4xl font-normal lg:font-light">
                  —Michael Moritz, Partner, Sequoia Capital,
                  <br /> Seed investor Google & Yahoo
                </span>
              </div>
            </div>
          </div>

          {/* Slice 12 */}

          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full px-4 lg:px-0 lg:pb-0 -mt-[1px] lg:mt-8"
          >
            <div className="purple-bg mt-0 lg:mt-16 rounded-[36px] px-4 lg:px-10 relative flex flex-col">
              <img src="/images/tongtong-gong.png" alt="Tong" className="hidden lg:flex" loading="lazy" />
              <div className="lg:absolute lg:top-[40px] lg:right-[40px] lg:left-[40px] lg:bottom-[40px] flex flex-col lg:flex-row justify-between mt-[-1px]">
                <div className="h-full w-full lg:w-max flex flex-col">
                  <div className="self-start ">
                    <h1 className="mb-10 pt-4 lg:pt-0 font-light lg:w-2/3 text-4xl lg:text-[51px]">
                      Introducing the Wizards
                    </h1>
                    <h4 className="flex lg:hidden mb-10 lg:mb-0 font-normal lg:font-light">
                      Our Wizards are industry disrupters who lead Cryptonite discussions and debates and direct members
                      to the innovation trends, investment opportunities and emerging companies that matter.
                    </h4>
                    <div className="w-full flex justify-center lg:justify-start mb-10 lg:mb-0">
                      <button className="orange-button text-sm px-4 py-1 font-bold">Meet some Wizards video!</button>
                    </div>
                  </div>
                  <div className="w-full">
                    <img src="/images/tongtong-gong.png" alt="Tong" className="flex lg:hidden w-full h-auto" />
                  </div>
                  <div className="h-full hidden lg:flex flex-col justify-end">
                    <p className="lg:font-bold font-xs lg:!font-sm">Tongtong Gong </p>
                    <p className="lg:font-normal font-xs lg:!font-sm">CoFounder & CTO,</p>
                    <p className="lg:font-normal font-xs lg:!font-sm">Amberdata </p>
                    <p className="lg:font-normal font-xs lg:!font-sm">Cryptonite Wizard</p>
                  </div>
                  <div className="h-full flex lg:hidden flex-col justify-end text-center text-sm">
                    <h4 className="lg:font-bold !text-sm">Tongtong Gong CoFounder & CTO,</h4>
                    <h4 className="lg:font-semibold !text-sm">Amberdata Cryptonite Wizard</h4>
                  </div>
                </div>
                <div className="w-full lg:w-1/4  text-center lg:text-right custom-text-size h-full flex flex-col justify-center">
                  <h3 className="orange-text mb-10 lg:mb-4 font-bold mt-6 lg:mt-0 text-base lg:text-[22px] ">
                    {" "}
                    Turning the World Around One More Time
                  </h3>
                  <h3 className="hidden lg:flex lg:text-[22px] font-light">
                    Our Wizards are industry disrupters who lead Cryptonite discussions and debates and direct members
                    to the innovation trends, investment opportunities and emerging companies that matter.
                  </h3>
                </div>
              </div>
            </div>

            <div
              className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 pt-14 lg:pt-0 lg:px-0 lg:pb-0 lg:mt-2"
            >
              <div className="black-bg container mx-auto relative rounded-[20px] lg:rounded-[0px] p-4 lg:p-0">
                <div className="w-full flex flex-col lg:flex-row justify-center items-center">
                  <img
                    src="/images/wizard hat icon.svg"
                    alt="wizard hat"
                    className="
              absolute top-[-35px] lg:top-[-50px] w-[70px] lg:w-[120px]"
                    loading="lazy"
                  />
                </div>

                <div className="flex text-sm color-light-gray  w-full text-justify">
                  Ana Lukner Roljic, Founder & Chairman, Truhoma + Tim Draper, Founder, Draper Associates + Magomed
                  Musaev, Founder & President, GVA Capital + Donald Basile, CEO, Monsoon Blockchain Storage + Mark
                  Drummond, AI & Machine Learning, Apple + Tongtong Gong, CTO, Amberdata.io + Roger Ver, Founder,
                  Bitcoin Cash + Sonja Perkins, Founder, Broadway Angels + Jeremy Liew, Partner, Lightspeed Ventures +
                  Brad Stephens, Co-Founder & Managing Partner, Blockchain Capital + Pam Alexander, Angel Investor, Next
                  Ventures + Richard Ma, CEO, Quantstamp Technologies + Sam Harrison, Managing Partner, Blockchain
                  Ventures + Bart Stephens, Co-Founder & Managing Partner, Blockchain Capital + Heidi Messer,
                  Chairperson & Co-Founder, Collective + David Kunin , Co-founder & CEO, Inflexhn Partners + Josh
                  Lawler, Partner, Zuber Lawler LLP + Linda Lawrence, Founder, Zero1 + Howard Morgan, Chairman, B
                  Capital Group + Michele Sconiers, Co-Founder & COO, Level Therapy Group + Scott English, Managing
                  Director & Head of U.S. Investments, Hearst Ventures + Ann Grimes, Editor & Educator, Stanford School
                  of Design + Amy Wu, Partner, Lightspeed Ventures + Doug Park, Crypto Lawyer, Park and Dibadj + Neil
                  Gerber, Director, New Energy & Blockchain, IBM + Petra Vorsteher, Founder, AI.Hamburg + Heather Hiles,
                  Managing Partner, Black Ops Fund + Keith Agoada, CEO, Producers Token + Marie Alexander, Angel
                  Investor + Michael Arrington, Founder & Partner, Arrington XRP Capital + Larry Augustin, VP, Amazon
                  Web Services (AWS) + Rok Babič, CTO, Eligma Labs + Saswata Basu, Founder & CEO, 0chain + David Chaum,
                  Founder & Cryptographer, DigiCash + Kevin Collins, Managing Director, Software & Platforms Industry,
                  Accenture + Shawn Douglass, CEO, Amberdata.io + Tess Hau, Founder & CEO, Tess Ventures + Atsushi
                  Hisatsumi, Founder & CEO, PATRON + Bess Ho, Angel Investor, GSQ + André Horta, CEO, BitcoinToYou +
                  Dejan Roljic, Founder & CEO, Eligma + Joe Schoendorf, Founding Partner, Accel Partners + Michelle
                  Tsing, Co-Founder, Governance Research Institute + William Barhydt, CEO, Abra + Andrey Zamovsky,
                  Founder & CEO. Ambisafe + Michael Petersohn, Managing Director, ARC Financial Technologies + Gil
                  Valentine, Founder & CCO, Athena Bitcoin + Bá Minuzzi, Co-founder & Managing Partner, Ausum Ventures +
                  Sonny Singh, CCO, BitPay + Michael Skok, Co-Founder & Partner, Underscore VC + Duncan Davidson,
                  Founder & Managing Director, Bullpen Capital + Christina Yang, Managing Partner, SteelBay Equity
                  Partners + Andrew Collins, Co-Founder & CEO, Bungalow + George Gilder, Founder, Gilder Technology +
                  Gerrit Van Wingerden, CTO, Caspian + Henrik Hjelte, CEO, ChromaWay + Jordon Hall, Co-Founder, Civium +
                  Daniel Polotsky, Founder & CEO, CoinFlip + Eddy Travia, CEO, Coinsilium + Massimo Bertaccini, CEO,
                  Cryptolab + Jeff Wentworth, Co-Founder, Curvegrid + Jessica Segal, Partner, DFJ Growth + Guy Zyskind,
                  Co-Founder & CEO, Enigma + Alexi Lane, CEO, Everex + Alpesh Doshi, Founder & CEO, Fintricity + Salem
                  Bagami, Founder & Director, Founder Institute + Lewis Katz, CBO, FS Investments + Jonny Peters,
                  Founder & CEO, Gaze Coin + Josh Elman, Partner, Greylock Partners + Mike Gault, Founder & CEO,
                  Guardtime + Clément Lesaege, CTO, Kleros + Ian Worrall, Founder & CEO, MyBit AG + Jon Jacobs, Founder
                  & CEO, NeverDie Studios + Abhishek Pitti, Founder & CEO, Nucleus Vision + Azam Zariff, Founder,
                  Investify + Alessandro Piol, Managing Partner & Co-Founder + AlphaPrime Ventures + Brendan Playford,
                  Co-Founder & CEO, PngMe + Russell Korus, CEO, PotCoin + Cate Rung, CCO & Co-Founder, PngMe + Natalia
                  Karayaneva, CEO, Propy + Patrick Dai, Founder, Qtum + David Menard, Founder & CEO, Real Items + Sean
                  Walsh, Founder & CEO, Redwood City Ventures + Michael Moll, CTO, Reitium Blockchain Technologies LTD +
                  Scott Irwin, General Partner, Rembrandt Venture Partners + James Seg, Founder & CEO, Riptide Coin +
                  Jen Horonjeff, PhD, Founder & CEO, Savvy Cooperative + Joy Ajlouny, Co-Founder, fetchr & Bonfaire +
                  Phillippe Khan, Founder & CEO, Fullpower + Parul Gujral, CEO, Snowball + Jim Liew, Co-Founder &
                  Partner, Sokat + ChrisMcCoy, CEO, Storecoin + Abram Cookson, CIO & Co-Founder, Substratum + Rufo
                  Guerreschi, CEO, TRUSTLESS:AI + Dominik Zynis, Co-Founder, WINGS DAO + Alisa Gus, CEO, WishKnish +
                  Keith Nilsson, Managing Partner, Xplorer Capital + Kenneth Goldman, Former CFO, Yahoo + Doug Scott,
                  Partner, Surround Ventures + Tony Zhang, Managing Director, Ventek Ventures + Steven XI, Co-Founder &
                  Managing Partner, Eastlink Capital Partners, L.P. + David Yang, Founder, Yva.ai, + Ron Yekutiel,
                  CoFounder & CEO, Kaltura + Eleanor Wu, President, Blue Ocean Systems + David Siegel, Founder,
                  Kryptodesign.com + Yoav Dror, Founder & CEO, Pumapay + Sasha Johnson, CEO, VC.House + Deepak Kamra,
                  General Partner + Patrick Li, CTO, AlohaCloud Digital Networks + Dovydas Riasnojus, ,CEO, Swace
                  Foundation + Norm Fogelsong, General Partner, IVP + Alvin Foo, Co-Founder, DAOventures + Patri
                  Friedman, Managing Partner, Zarco Investment Group + Clarence Wooten, Director & Entrepreneur In
                  Residence, Google + +Kim Vogel, Board Director, Trico + Sam Zaid, Founder & CEO, Getaround + Steven
                  Sprague, CEO, Rivetz Corp + Jay Samit, Executive Chairman, Greenfield Robotics + Susan Oh CMO Beyond
                  Imagination + Eric Alexandre, Executive Director & Founder Ikon Media|Jetcoin + Grace Tang Founder &
                  CEO LookRev + Richard Bohan, CEO & CoFounder, Crypto Capital Investments + Janine Terrano Founder &
                  CEO Topia Technology + Adriaan Brink, Founder & CEO, FUN Token + Patrick Chung, General Partner, Xfund
                  + Hayden Gill, CEO, Atlas Power + Melisande Mual Managing Director, The Paypers + Omar Green, Founder
                  & CEO, Wallet.AI +Andrew Grosser, Founder & CEO, SFPL + Marc Kenigsberg, Chief Marketing Officer,
                  Antidote Health + Matthew Liu, Cofounder, Origin Protocol, + Paul Mahal, CEO & Founder, Stanford
                  Ventures + Ismail Malik, CEO & Founder, Blockchain Lab + Nikolaj Martyniuk, Co-Founder & CEO, WePower
                  Network + Mike Reaves, VP of Product, AutoRABIT + James Robinson Founder & General Partner RRE
                  Ventures + Mouli Srini, Founder & CEO, MOBODEXTER + Sarah Austin, Head of Content, Kava Labs +
                </div>
                <div className="w-full h-full absolute z-[5] top-0 left-0">
                  <div className="w-full h-full flex flex-col justify-center items-center ">
                    <h1 className="mb-10 font-massive font-light text-center leading-10 lg:leading-[78px]">
                      The Wizards of Cryptonite
                    </h1>
                    <a href="https://tonyperkins.medium.com/cryptonite-wizards-b0a9da29b491" target="_blank" className="orange-button px-4 lg:px-8 py-2 font-bold lg:!text-xl flex w-max">
                      Apply to be a Wizzard
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Slice 13 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 lg:px-0 lg:pb-0 mt-[-1px] lg:mt-16"
          >
            <div className="purple-bg container mx-auto rounded-[36px] p-4 lg:p-10 flex flex-col lg:mt-10">
              <span className="w-full flex justify-end">
                <h1 className="w-full lg:w-1/2 text-center lg:text-left lg:pl-10 font-extralight lg:font-normal">
                  The CPRO Shop
                </h1>
              </span>
              <div className="flex flex-col lg:flex-row mt-4 ">
                <div className="w-full lg:w-1/2 pr-0 lg:pr-10 mb-4 lg:mb-0">
                  <img
                    src="/images/mini web.png"
                    alt="mini web"
                    className="w-full h-full hidden lg:flex"
                    loading="lazy"
                  />
                </div>
                <div className="w-full lg:w-1/2 flex flex-col justify-between">
                  <div className="h-full flex flex-col ">
                    <div className="flex flex-col lg:flex-row">
                      <span className="w-full lg:w-max flex justify-center h-max mb-4 lg:mb-0">
                        <img
                          src="/images/coin icon.svg"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="lg:pl-2 text-base lg:text-[22px]  text-center lg:text-left font-medium lg:font-normal">
                        Offers CPRO holders steep discounts on a myriad of live and online events, market and industry
                        research and intelligence, continuing education, subscriptions—including to top Cryptonite
                        Wizards—and travel and luxury goods and services
                      </p>
                    </div>
                    <div className="flex flex-col lg:flex-row mt-10">
                      <span className="w-full lg:w-max flex justify-center h-max mb-4 lg:mb-0">
                        <img
                          src="/images/coin icon.svg"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="lg:pl-2 text-base lg:text-[22px]  text-center lg:text-left font-medium lg:font-normal">
                        <span>A decentralized e-commerce platform offering a </span> direct and fully transparent
                        buyer-to-seller transaction environment.
                      </p>
                    </div>
                    <div className="flex flex-col lg:flex-row mt-10">
                      <span className="w-full lg:w-max flex justify-center h-max mb-4 lg:mb-0">
                        <img
                          src="/images/coin icon.svg"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="lg:pl-2 text-base lg:text-[22px] text-center lg:text-left font-medium lg:font-normal mb-8 lg:mb-0">
                        Wizards, Founding Members, and Network Partners earn CPRO Shop sales commissions on all
                        purchases made by members who signed up with their invitation links.
                      </p>
                    </div>
                  </div>
                  <img src="/images/mini web.png" alt="mini web" className="w-full flex lg:hidden" loading="lazy" />
                  <div className="w-full lg:pl-10">
                    <a
                      className="orange-button px-2 py-2 font-bold w-full !text-lg mt-10 mb-4 lg:mb-0"
                      href="https://cryptoniteventures.com/cproshop-vendor-application/"
                      target="_blank"
                    >
                      Sell your products and services in the CPRO Shop
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Slice 14 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full pb-4 px-4 lg:px-0 lg:pb-0 mt-[-1px] lg:mt-8"
          >
            <div className="flex flex-col lg:flex-row container mx-auto w-full lg:mt-6 lg:h-[85vh] xl:h-[80vh] 2xl:max-h-[700px]">
              <div className="orange-bg rounded-[36px] w-full lg:w-2/3 p-4 lg:p-6 mr-6  ">
                <span className="w-full flex flex-col lg:flex-row justify-center">
                  <h1 className="w-full text-center mb-10 font-extralight text-4xl lg:text-[42px]">
                    A Metaverse of New Wealth and Opportunity
                  </h1>
                </span>
                <div className="flex flex-col lg:flex-row gap-6">
                  <div className="w-full lg:w-1/2 ">
                    <div className="flex flex-col lg:flex-row mb-6">
                      <span className="w-full flex justify-center lg:w-max h-max mb-4 lg:mb-0">
                        <img
                          src="/images/CPRO.png"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="pl-2 text-xl lg:text-[22px]  font-normal text-center lg:text-left ">
                        Build a network of partners, customers, investors and friends.
                      </p>
                    </div>
                    <div className="flex flex-col lg:flex-row mb-6">
                      <span className="w-full flex justify-center lg:w-max h-max mb-4 lg:mb-0">
                        <img
                          src="/images/CPRO.png"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="pl-2 text-xl lg:text-[22px] font-normal text-center lg:text-left">
                        Broadcast your expertise by sharing content and perspective.
                      </p>
                    </div>
                    <div className="flex flex-col lg:flex-row mb-6">
                      <span className="w-full flex justify-center lg:w-max h-max mb-4 lg:mb-0">
                        <img
                          src="/images/CPRO.png"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="pl-2 text-xl lg:text-[22px] font-normal text-center lg:text-left">
                        Join exclusive Tribes and become and industry insider.
                      </p>
                    </div>
                    <div className="flex flex-col lg:flex-row mb-6">
                      <span className="w-full flex justify-center lg:w-max h-max mb-4 lg:mb-0">
                        <img
                          src="/images/CPRO.png"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="pl-2 text-xl lg:text-[22px] font-normal text-center lg:text-left">
                        Cash-in on CPRO rewards for cash and steep discounts.
                      </p>
                    </div>
                  </div>
                  <div className="w-full lg:w-1/2 ">
                    <div className="flex flex-col lg:flex-row mb-6">
                      <span className="w-full flex justify-center lg:w-max h-max mb-4 lg:mb-0">
                        <img
                          src="/images/CPRO.png"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="pl-2 text-xl lg:text-[22px] font-normal text-center lg:text-left">
                        Create new wealth by earning CPRO rewards and commissions.
                      </p>
                    </div>
                    <div className="flex flex-col lg:flex-row mb-6">
                      <span className="w-full flex justify-center lg:w-max h-max mb-4 lg:mb-0">
                        <img
                          src="/images/CPRO.png"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="pl-2 text-xl lg:text-[22px] font-normal text-center lg:text-left">
                        Enjoy exclusive subscriptions to top Wizards for advice and access.
                      </p>
                    </div>
                    <div className="flex flex-col lg:flex-row mb-6">
                      <span className="w-full flex justify-center lg:w-max h-max mb-4 lg:mb-0">
                        <img
                          src="/images/CPRO.png"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="pl-2 text-xl lg:text-[22px] font-normal text-center lg:text-left">
                        Be the first to hear about innovation trends and hot companies.
                      </p>
                    </div>
                    <div className="flex flex-col lg:flex-row mb-6">
                      <span className="w-full flex justify-center lg:w-max h-max mb-4 lg:mb-0">
                        <img
                          src="/images/CPRO.png"
                          alt="coin icon"
                          className="min-w-[40px] min-h-[40px] max-h-[40px] max-w[40px]"
                          loading="lazy"
                        />
                      </span>
                      <p className="pl-2 text-xl lg:text-[22px] font-normal text-center lg:text-left">
                        Gain new insights, test ideas, and solve problems.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-[36px] w-full lg:w-1/3 h-full  lg:mt-0 overflow-hidden object-cover ">
                <img
                  src="/images/side image.png"
                  alt="Unversum"
                  className="h-full w-full hidden lg:flex"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 md:pt-4 lg:pt-0 lg:px-0 lg:pb-0 mt-[-1px] lg:mt-20 lg:mb-10"
          >
            <div className="black-bg container w-full mx-auto lg:mt-16 rounded-[36px]">
              <div className="w-full flex justify-center ">
                <img
                  alt="buble pic"
                  src="/images/Bubble.svg"
                  className="mb-10 w-[100px] lg:w-[150px] mt-16 lg:mt-0"
                  loading="lazy"
                />
              </div>
              <div className="w-full flex justify-center">
                <h1 className="text-center p-4 lg:p-10 font-normal lg:font-light text-base md:text-2xl lg:text-4xl lg:leading-[52px] w-[95%]">
                  “Oh I get it! Cryptonite is the OnlyFans for innovation influencers. You can cozy up to your favorite
                  innovators and be the first to know what they are thinking and doing. If you’re lucky, they might even
                  rate your deck. ”
                  <br />
                  <p className="orange-text mt-4 text-center w-full font-normal lg:font-light text-base md:text-2xl lg:text-4xl">
                    —Anonymous Cryponite Wizard
                  </p>
                </h1>
              </div>
            </div>
          </div>

          {/* Slice 15 */}
          <Element name="roadmap">
            <CproRoadmap />
          </Element>

          {/* Slice 16 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 lg:px-0 lg:pb-0 mt-[-1px] lg:mt-8"
          >
            <div className="purple-bg container mx-auto rounded-[36px] p-4 pb-0 lg:p-10 flex flex-col lg:flex-row lg:mt-10 relative overflow-hidden cpro-distribution">
              <div className="w-full lg:w-5/12">
                <div className="w-full lg:w-4/5">
                  <h1 className="mb-10 font-extralight text-4xl lg:text-[60px] lg:leading-[60px] ">
                    CPRO Distribution
                  </h1>
                  <h3 className="mb-4 text-base lg:text-[22px] font-normal">
                    <span className="lg:font-bold">
                      Cryptonite’s tokenomic strategy <br />
                    </span>
                    maximizes value by growing our powerful holder base and expanding CPRO discount purchasing power.
                    CPRO Ventures also increases distribution and liquidity by investing CPROs in quality communities
                    that adapt our reward token.
                  </h3>
                  <h3 className="font-bold text-base lg:text-xl">
                    CPRO Supply: <span className="orange-text">21 billion</span>
                  </h3>
                  <h3 className="font-bold text-base lg:text-xl">
                    Network: <span className="orange-text">Binance Smart Chain</span>
                  </h3>
                  <h3 className="font-bold text-base lg:text-xl">
                    Token Type: <span className="orange-text">BEP-20 Utility</span>
                  </h3>
                </div>
              </div>

              <div className="w-full lg:w-2/12 mt-10 lg:mt-0">
                <div className="text-center lg:text-left">
                  <h1 className="orange-text text-[48px] font-extralight">34%</h1>
                  <h4 className="font-bold">Public Distribution </h4>
                  <h4 className="font-normal">No CPRO sales restrictions</h4>
                </div>
                <div className="text-center lg:text-left mt-6 lg:mt-0 ">
                  <h1 className="orange-text text-[48px] font-extralight">30%</h1>
                  <h4 className="font-bold">Cryptonite Network </h4>
                  <h4 className="font-normal">First CPRO Trusted Network</h4>
                </div>
                <div className="text-center lg:text-left mt-6 lg:mt-0">
                  <h1 className="orange-text text-[48px] font-extralight">30%</h1>
                  <h4 className="font-bold ">Network partners </h4>
                  <h4 className="font-normal">Curated online communities that reward CPROs.</h4>
                </div>
                <div className="text-center lg:text-left mt-6 lg:mt-0">
                  <h1 className="orange-text text-[48px] font-extralight">5%</h1>
                  <h4 className="font-bold">CPRO Ventures</h4>
                  <h4 className="font-normal">Team invests in quality Network Partners over 5 to 7 year horizon.</h4>
                </div>
                <div className="text-center lg:text-left mt-6 lg:mt-0 mb-8 lg:mb-0">
                  <h1 className="orange-text text-[48px] font-extralight">1%</h1>
                  <h4 className="font-bold">EDU Token Foundation</h4>
                  <h4 className="font-normal">Stable coin for P2P scholarships</h4>
                </div>
              </div>
              <img src="/images/graph-mobile.png" alt="Graph" className="h-auto w-full flex lg:hidden" loading="lazy" />
              {/* <div className="lg:w-4/12 lg:h-full lg:absolute top-0 right-0  bottom-0 hidden lg:flex">
                <img
                  src="/images/graph side insert.svg"
                  alt="Graph"
                  className="h-full w-full object-cover z-50"
                  loading="lazy"
                />
              </div> */}
            </div>
          </div>

          {/* Slice 17 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 lg:pt-0 lg:px-0 lg:pb-0 lg:mt-8 mb-16"
          >
            <div className="green-bg container mx-auto rounded-[36px] px-4 lg:px-4 py-8 lg:mt-8 flex flex-col items-center">
              <div className="w-full flex justify-center">
                <img src="/images/star badge.svg" alt="Star Badge" className="w-[75px] lg:w-[125px]" loading="lazy" />
              </div>
              <div className="w-full text-center mt-6 !text-2xl mb-10 font-light lg:font-normal">
                <h1 className="dark-purple-text leading-[44px] lg:leading-[68px] lg:text-[60px] font-extralight mb-10">
                  Founding Member - Special Offer!
                </h1>
              </div>
              <div className="w-full flex flex-col lg:flex-row justify-center lg:gap-16">
                <div className="w-full lg:w-1/3 text-lg mb-3 lg:mb-0 custom-h3">
                  <h3 className="text-black lg:gray-text mb-3 flex">
                    <span className="h-full mr-3 text-black">• </span>Lifetime distinction as a ‘Cryptonite Founding
                    Member’ with an accompanying NFT-based badge displayed on your profiles and posts.{" "}
                  </h3>
                  <h3 className="text-black lg:gray-text  mb-3 flex">
                    <span className="h-full mr-3 text-black">• </span>A three-year Premium-Plus membership ($2,100
                    value).{" "}
                  </h3>

                  <h3 className="text-black lg:gray-text  mb-3 flex">
                    {" "}
                    <span className="h-full mr-3 text-black">• </span>Full access to all top company and investor
                    analytics.{" "}
                  </h3>
                  <h3 className="text-black lg:gray-text flex">
                    <span className="h-full mr-3 text-black">• </span>Advanced member search and messaging.
                  </h3>
                </div>
                <div className="w-full lg:w-1/3  text-lg mb-3 lg:mb-0 custom-h3">
                  <h3 className="text-black lg:gray-text  mb-3 flex">
                    <span className="h-full mr-3 text-black">• </span>Power to download connection profiles and other
                    critical data.
                  </h3>
                  <h3 className="text-black lg:gray-text  mb-3 flex">
                    <span className="h-full mr-3 text-black">• </span>Unlimited comments and ratings on homepage content
                    and Cryptonite Live!.
                  </h3>

                  <h3 className="text-black lg:gray-text  mb-3 flex">
                    {" "}
                    <span className="h-full mr-3 text-black">• </span>Preferential comments placement over those of
                    regular members.
                  </h3>
                  <h3 className="text-black lg:gray-text flex ">
                    <span className="h-full mr-3 text-black">• </span>Join Cryptonite Wizards in voice-based discussions
                    and debates.
                  </h3>
                </div>
                <div className="w-full lg:w-1/3  text-lg mb-3 lg:mb-0 custom-h3">
                  <h3 className="text-black lg:gray-text  mb-3 flex">
                    <span className="h-full mr-3 text-black">• </span>Nominate up to 3 new Cryptonite members a month
                  </h3>
                  <h3 className="text-black lg:gray-text  mb-3 flex">
                    <span className="h-full mr-3 text-black">• </span>Start a Tribe around your areas of interest and
                    expertise.
                  </h3>

                  <h3 className="text-black lg:gray-text flex">
                    <span className="h-full mr-3 text-black">• </span>Serve as a member of the ‘Cryptonite Founding
                    Member Tribe.’
                  </h3>
                </div>
              </div>
              <div className="w-full flex flex-col items-center mt-10 custom-h3 px-4">
                <button
                  className="purple-button font-bold w-max text-base lg:text-xl py-2 px-3 lg:py-3 lg:px-10 mb-10 lg:mb-0 justify-center max-w-full"
                  onClick={() => interceptModalChange("founding")}
                >
                  Become a Cryptonite Founding Member!
                </button>
                <h3 className="font-medium text-black text-opacity-90 mt-4 lg:px-0 text-xl lg:text-base text-center lg:text-left">
                  Enjoy the Founding Member status and benefits with a minimum $1,950 CPRO purchase!
                </h3>
              </div>
            </div>
          </div>
          <UserContext.Consumer>
            {(value) => (
              <Privatepresale
                openFoundingModal={() => interceptModalChange("founding")}
                openBuyCproModal={() => interceptModalChange("buyCpro")}
                stoIsActive={(value) => setIsStoActive(value)}
                stoPricePerCoin={(value) => setStoPricePerCoin(value)}
                user={value}
                openRegisterModal={() => interceptModalChange("register")}
              />
            )}
          </UserContext.Consumer>

          {/* Slice 18 */}
          <div
            className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 px-4 pt-4 lg:pt-0 lg:px-0 lg:pb-0 lg:mt-8"
          >
            <div className="container mx-auto w-full h-full flex flex-col lg:flex-row">
              <div className="relative lg:gap-10 w-full lg:w-2/3 mr-6">
                <img
                  alt="universe pic"
                  src="/images/slice 18.png"
                  className="absolute lg:static rounded-[36px] w-full h-full hidden lg:flex"
                  loading="lazy"
                />
                <img
                  alt="text"
                  src="/images/slice 18 badges.svg"
                  className="absolute w-full height-calculation top-[16px] hidden lg:flex"
                  loading="lazy"
                />
                <div className="universum-background w-full flex flex-col lg:hidden items-end p-8 pr-4 ">
                  <div className="orange-bg p-3 text-xs text-left rounded-[20px] mb-2 w-2/3">
                    <b>Cryptonite Network Member</b> <br />
                    Build reputation and earn CPROs.
                  </div>
                  <div className="orange-bg p-3 text-xs text-left rounded-[20px] mb-2 w-2/3">
                    <b>CPRO Investor</b> <br />
                    CPRO Investor Buy, earn, store, trade, reward peers, with CPRO.
                  </div>
                  <div className="orange-bg p-3 text-xs text-left rounded-[20px] mb-2 w-2/3 ">
                    <b>CPRO Network Partner</b> <br />
                    Build community with CPRO rewards.
                  </div>
                  <div className="orange-bg p-3 text-xs text-left rounded-[20px] mb-2 w-2/3">
                    <b>CPRO Shop Vendor </b> <br />
                    Promote and sell in decentralised store.
                  </div>
                  <div className="orange-bg p-3 text-xs text-left rounded-[20px] mb-2 w-2/3">
                    <b>CPRO Brand Partner</b> <br />
                    Build industry brand influence.
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/3 purple-bg rounded-[36px] flex flex-col items-center justify-center py-10 lg:p-0 px-6 mt-4 lg:mt-0">
                <h1 className="text-center font-extralight lg:px-8 text-[42] w-4/5 leading-[2.75rem]">
                  Claim your stake in the Cryptonite Metaverse
                </h1>
                <a
                  className="orange-button px-4 py-2 font-bold mt-10 lg:mt-20"
                  href="https://cryptoniteventures.com/cproshop-vendor-application/"
                  target="_blank"
                >
                  Let's make it happen
                </a>
              </div>
            </div>

            {/* Slice 19 */}

            <div
              className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 pt-4 lg:pt-0 lg:px-0 lg:pb-0 lg:mt-8"
            >
              <img
                src="/images/girl-ilustration mobile.png"
                alt="Girl"
                className="w-full lg:h-full flex lg:hidden rounded-[20px] h-[170px]"
              />
              <div className="w-full h-full rounded-[36px] flex gray-bg mt-2 lg:mt-8 overflow-hidden">
                <div className="w-full lg:w-[43%] flex-col justify-start lg:justify-center h-full relative hidden lg:flex">
                  <img
                    src="/images/slice 19 image_1.png"
                    alt="Girl"
                    className="w-full h-full scale-[1.05] mt-[18px]"
                    loading="lazy"
                  />
                  <div className="w-[100px] absolute top-[50%] right-[-50px]">
                    <img
                      src="/images/slice 19crypto negative.svg"
                      alt="coin negative"
                      className="w-full h-full "
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="px-4 lg:pl-20 pb-4 lg:pr-10 w-full lg:w-2/3 h-full flex flex-col lg:justify-between py-9 lg:py-6">
                  <h1 className="mb-4 text-4xl lg:text-[51px] font-extralight">Cryptonite PRO Legend</h1>

                  <h3 className="text-light font-base lg:font-lg text-[22px] leading-8">
                    There is a rising global citizen movement to take back our privacy, freedom, and dignity from
                    centralized powers. The list of such powers is long and includes the CCP, Wall Street, Big Tech, the
                    ’Top Schools,’ Hollywood, Mainstream Media, Central Banks, and all governments and public agencies.
                    To watch out for the little guy, the early founders of Bitcoin created a technology metaphor to
                    radically diminish centralized power.
                    <br />
                    <br />
                  </h3>

                  <h3 className="text-light text-[22px] leading-8">
                    Inspired by this spirit, thousands of blockchain entrepreneurs are now building decentralized apps
                    across all industries. Increasingly, people will get business done privately, peer-to-peer, and
                    without central administration, middlemen, and their unnecessary fees. We are launching the
                    Cryptonite PRO to fuel the global innovators and risk investors driving the Counter Revolution to
                    take back the power. We hope you will join us.
                    <br />
                    <br />
                  </h3>
                  <h3 className="text-light text-[22px] leading-8 italic">
                    -Anthony Perkins, founder & editor, Cryptonite
                  </h3>
                </div>
              </div>
            </div>

            <div
              className="
          bg-white lg:bg-black container mx-auto w-full h-full pb-4 pt-4 lg:pt-0 lg:px-0 lg:pb-0 lg:mt-8"
            >
              <div className="purple-bg w-full lg:mt-8 rounded-[36px] text-base sm:text-[22px] font-light p-6">
                Disclaimer: The Cryptotonite PRO (CPRO) is a utility token that resides on the Binance Smart Chain
                (BSC), an Ethereum Virtual Machine-capable public blockchain owned by Binance, the world’s biggest
                bitcoin and cryptocurrency exchange in the world by volume. As a utility token, the CPRO does not
                represent an ownership stake in a legal entity, nor do CPRO holders receive dividends. The CPRO’s
                primary economic purpose is to serve as a reward, service access, and discount token within the
                Cryptonite decentralized social networking application and any future network partners who adopt the
                CPRO as their reward token. The founders of Cryptonite plan to sell CPROS to the public on a pre-Initial
                Coin Offering (ICO) basis and list our token on the top decentralized exchanges and Binance within the
                calendar year 2022. However, a potential CPRO ICO is subject to regulatory and market conditions out of
                the control of Cryptonite and, therefore, can not be guaranteed.
              </div>
            </div>
          </div>

          {/* Slice 20 */}
          <Footer scrollToRoadmap={() => scrollToElement("roadmap")} />
        </div>
        {renderModal()}
      </Element>
    </UserContext.Provider>
  );
}

export default App;

export { UserContext };
